import React from "react";
import { Grid, CardContent, Card, Typography } from "@material-ui/core";
import viziLogo from "../../common/global/images/vizilogo.png";
import { viziLogoTermsStyle } from "../../common/global/globalStyle/ImageStyle";
import { termsCardStyle } from "../../common/global/globalStyle/CardStyle";
import { htmlDataforEnglish, htmlDataforSpanish } from "../../common/staticData/termsandconditionData";
import {
  termsConditionMainHeading,
} from "../../common/global/globalStyle/TypographyStyle";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationSpanish } from "../../common/global/localzation/LocalizationData";
import { translationEnglish } from "../../common/global/localzation/LocalizationData";
import LanguageDetector from "i18next-browser-languagedetector";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import "../../common/reusableComponent/global.css"

export default function TermsConditionPage() {
  const { t } = useTranslation();
  const [isLangSpanish,setIsLangSpanish] = useState(false);
  const search = useLocation().search;
  
  useEffect(() => {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: translationEnglish,
          },
          sp: {
            translation: translationSpanish,
          },
        },
      });

    let language = new URLSearchParams(search).get("translation");
    if(language === 'sp') { setIsLangSpanish(true)}
    i18n.changeLanguage(language);
  }, []);


  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11} sm={10} md={10} lg={8} style={{}} align="center">
          <img src={viziLogo} alt="" style={viziLogoTermsStyle} />

          <div className="ErrorPage_content">
            <Card style={termsCardStyle} elevation={0}>
              <CardContent>
                <Typography
                  variant="h4"
                  style={termsConditionMainHeading}
                  fontSize={{
                    lg: 2000,
                    md: 10,
                    sx: 20,
                  }}
                >
                  {t("termspage")}
                </Typography>
                <div className = "data-list" dangerouslySetInnerHTML={{ __html: isLangSpanish ? htmlDataforSpanish : htmlDataforEnglish }}></div>                 
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
