import axios, { Axios } from "axios";

class Http extends axios {
  static BASE_URL = process.env.REACT_APP_API_URL;

  static get(url, token) {
    if (token)
      return axios.get(`${this.BASE_URL}/${url}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    else return axios.get(`${this.BASE_URL}/${url}`);
  }

  static post(url, data, token) {
    if (token)
      return axios.post(`${this.BASE_URL}/${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    else return axios.post(`${this.BASE_URL}/${url}`, data);
  }

  static test(url) {
    return fetch(`${this.BASE_URL}/${url}`);
  }

  static patch(url, data, token, queryParam) {
    if (token)
      return axios.patch(`${this.BASE_URL}/${url}?${queryParam}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    else return axios.patch(`${this.BASE_URL}/${url}`, data);
  }

  static delete(url, token) {
    if(token) {
      return axios.delete(`${this.BASE_URL}/${url}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      return axios.delete(`${this.BASE_URL}/${url}`);
    }
  }
}

export default Http;
