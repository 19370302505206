import Joi from "joi-browser";
import { translationSpanish } from "../localzation/LocalizationData";
import { translationEnglish } from "../localzation/LocalizationData";

class schema {
  static validateLoginForm(user) {
    // Handling the Localization of Validations Messages.

    const locale = navigator.language;
    let translateStore = { ...translationEnglish };

    if (locale === "es") {
      translateStore = { ...translationSpanish };
    } else {
      translateStore = { ...translationEnglish };
    }

    // Handling the Regex of joi for multiple Test Cases.
    // If you don't use this type of regex handling , it'll cause issue when we handling the Id value
    // validation without giving ID Type.

    const validreg = () => {
      switch (true) {
        case user.idTypeId === 5:
          return "^[0-9]+$";
        case user.idTypeId === 4:
          return "^[a-zA-Z0-9]*$";

        case user.idTypeId === 6:
          return "^[a-zA-Z0-9]*$";

        case user.idTypeId === undefined:
          return "";

        case user.idTypeId === "":
          return "";

        default:
          return "";
      }
    };

    // Handling the IdValue message on multiple scenerios of Selected IDtype .

    let msg =
      user.idTypeId === 5 && user.idValue.length > 0
        ? `${translateStore.dnierror}`
        : "" || user.idTypeId === 5
        ? `${translateStore.idValuerror}`
        : "" || user.idTypeId === 6
        ? `${translateStore.passporteerror}`
        : "" || user.idTypeId === 4
        ? `${translateStore.ceerror}`
        : "" || user.idTypeId === null
        ? ""
        : "";

    // Handling the length of DNI , CE, passporte.
    // As per guideliness  DNI require exact 8 digit ,  CE , Passporte required 12 digit.
    // vallengthformax, vallengthformin are part of DNI Digit System

    let vallengthformax = user.idTypeId === 5 ? 8 : 12;
    let vallengthformin = user.idTypeId === 5 ? 8 : 0;

    // Handling the Valid Pasword is required  validation scenerio

    let valid = new RegExp("^\\S*$").test(user.password);

    // Handling the ValidPassword and  Password required , 8 Characters scenerio message.
    // Need to handle this because if we give space before 8 character we'll get the message of Pasword must be 8 characters

    let passwordMessage = () => {
      switch (true) {
        case user.password === "" && valid === true:
          return `${translateStore.passworderror}`;

        case user.password.length > 0 && valid === true:
          return `${translateStore.passwordminerror}`;

        case valid === false:
          return `${translateStore.validpassworderror}`;

        default:
          return "";
      }
    };

    const passwordMax = () => {
      switch (true) {
        case user.idValue.length > 12 && user.idTypeId === "":
          return "notfound";

        case user.idValue.length > 12 && user.idTypeId === 4:
          return `${translateStore.celenError}`

        case user.idValue.length > 12 && user.idTypeId === 6:
          return `${translateStore.passportlenError}`;

        case user.idValue.length > 8 && user.idTypeId === 4:
          return `${translateStore.dnilenError}`;

        default:
        //   return "* DNI must be 8 Digit";
          return `${translateStore.dnilenError}`;

      }
    };

    // schema of Login Web form .
    const schema = {
      idTypeId: Joi.number()
        .required()
        .error((errors) => {
          return errors.map((error) => {
            switch (error.type) {
              case "number.min":
                return { message: "first msg" };
              case "number.regex.base":
                return { message: `${msg}` };
              case "number.base":
                return { message: `${translateStore.idtypeerror}` };
              case "any.required":
                return { message: `${translateStore.idtypeerror}` };
              case "any.empty":
                return { message: `${translateStore.idtypeerror}` };
              default:
                return { message: "" };
            }
          });
        }),

      idValue: Joi.string()
        .min(vallengthformin)
        .max(vallengthformax)
        .regex(new RegExp(validreg()))
        .required()
        .error((errors) => {
          return errors.map((error) => {
            switch (error.type) {
              case "string.min":
                return { message: `${translateStore.dnilenError}` };
              case "string.max":
                return { message: passwordMax() };
              case "string.regex.base":
                return { message: `${msg}` };
              case "any.empty":
                return { message: `${translateStore.idValuerror}` };
              default:
                return { message: "" };
            }
          });
        }),

      password: Joi.string()
        .regex(new RegExp("^\\S*$"))
        .min(8)
        .max(100)
        .empty()
        .required()
        .options({
          language: {
            any: {
              allowOnly: "es",
            },
          },
        })
        .error((errors) => {
          return errors.map((error) => {
            switch (error.type) {
              case "string.min":
                return { message: passwordMessage() };
              case "string.regex.base":
                return { message: `${translateStore.validpassworderror}` };
              case "string.empty":
                return { message: `${translateStore.passworderror}` };
              default:
                return { message: "" };
            }
          });
        }),
    };
    return Joi.validate(user, schema, { abortEarly: false });
  }

  static validateResetForm(user) {
    // Handling the Localization of Validations Messages.
    const locale = navigator.language;
    let translateStore = { ...translationEnglish };

    if (locale === "es") {
      translateStore = { ...translationSpanish };
    } else {
      translateStore = { ...translationEnglish };
    }

    // Handling the Valid Pasword is required  validation scenerio

    let valid = new RegExp("^\\S*$").test(user.password);

    // Handling the ValidPassword and  Password required , 8 Characters scenerio message.
    // Need to handle this because if we give space before 8 character we'll get the message of Pasword must be 8 characters

    let passwordmessage = () => {
      switch (true) {
        case user.password === "" && valid === true:
          return `${translateStore.passworderror}`;

        case user.password.length > 0 && valid === true:
          return `${translateStore.passwordminerror}`;

        case valid === false:
          return `${translateStore.validpassworderror}`;

        default:
          return "";
      }
    };

    // schema of resetPassword Web form

    let confirmpasswordMessage = () => {
      switch (true) {
        case user.password === "" && user.confirmpassword.length > 0:
          return "";

        case user.password.length > 0 && user.confirmpassword.length > 0:
          return `${translateStore.confirmpassword}`;

        default:
          return "";
      }
    };

    const resetpassword = {
      password: Joi.string()
        .regex(new RegExp("^\\S*$"))
        .min(8)
        .required()
        .error((errors) => {
          return errors.map((error) => {
            switch (error.type) {
              case "string.min":
                return { message: passwordmessage() };
              case "string.max":
                return { message: "second msg" };
              case "string.regex.base":
                return { message: `${translateStore.validpassworderror}` };

              case "string.min" && "string.regex.base  ":
                return { message: `${translateStore.validpassworderror}` };

              case "any.empty":
                return { message: `${translateStore.passworderror}` };
              default:
                return { message: "" };
            }
          });
        }),
      confirmpassword: Joi.any()
        .valid(Joi.ref("password"))
        .required()
        .options({
          language: {
            any: {
              allowOnly: `!! ${confirmpasswordMessage()}`,
            },
          },
        }),
    };

    return Joi.validate(user, resetpassword, { abortEarly: false });
  }
}

export default schema;
