import React from "react";
import "../../common/reusableComponent/global.css";
import appStoreIcon from "../../common/global/images/apple.png";
import iPhoneScreen from "../../common/global/images/iphone-white.png";
import playStoreIcon from "../../common/global/images/google.png";
import viziLogo from "../../common/global/images/vizilogo.png";
import {
  appStyle,
  vizidownloadStyle,
  playStyle,
  iPhoneStyle,
} from "../../common/global/globalStyle/ImageStyle";
import { Card, CardContent, Grid } from "@material-ui/core";
import { downloadappCardStyle } from "../../common/global/globalStyle/CardStyle";
import { downloadAppStyle } from "../../common/global/globalStyle/TypographyStyle";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function DownloadApp() {

  const {t} = useTranslation();

  return (
    <>
      <div className="DownloadApp_container">
        <Grid
          container
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={12}
            lg={10}
            
            align="center"
          >
            <img src={viziLogo} alt="" style={vizidownloadStyle} />

            <Card style={downloadappCardStyle} elevation={0}>
              <CardContent sx={1} md={10} lg={6}>
                <Typography variant="h4" style={downloadAppStyle}>
                  {t("downloadheading")}
                </Typography>
                <Typography variant="h4" style={downloadAppStyle}>
                  {t("establish")}
                </Typography>


                <img src={playStoreIcon} style={playStyle} alt="" />
                <img src={appStoreIcon} style={appStyle} />
                <br />
                <img src={iPhoneScreen} alt="" style={iPhoneStyle} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
