import { useEffect } from "react";
import Http from "../../common/global/http/HttpRequest";
import { Box, CircularProgress, } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function UnsubscribeOperator() {
    const params = new URLSearchParams(useLocation().search);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // Handling the Accessing of userID and token from the URL of webApp.
        const operatorId = params.get("operatorId");
        const token = params.get("token");
        if (operatorId && token) {
            Http.delete(`operator/unsubscribe/${operatorId}`, token).then(res => {
                window.open("about:blank", "_self");
                window.close();
            }).catch(err => {
                navigateToErrorPage(err.response.data.statusCode, t(`${err.response.data.code}`))
            });
        } else {
            navigateToErrorPage(401, t("NO_ACCESS"));
        }
    }, []);

    const navigateToErrorPage = (statusCode, response) => {
        navigate({
            pathname: `/content/errorpage`,
            search: `statusCode=${statusCode}&response=${response}`,
        });
    };

    // const setAppLanguage = () => {
    //     if (t('language') === 'es') {
    //         setAppLang('es');
    //     } else {
    //         setAppLang('en');
    //     }
    // };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box>
                <CircularProgress
                    size={70}
                    style={{
                        color: " #38CC86",
                    }}
                />
            </Box>
        </div>
    )
}