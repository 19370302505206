import React, {useEffect, useState} from "react";
import viziLogo from "../../common/global/images/vizilogo.png";
import congratLogo from "../../common/global/images/Congratulations.png";
import {
    CardContent,
    Card,
    Grid,
    Typography,
    Box,
    CircularProgress
} from "@material-ui/core";
import {congratCardStyle} from "../../common/global/globalStyle/CardStyle";
import {congratsHeading} from "../../common/global/globalStyle/TypographyStyle";
import "../../common/reusableComponent/global.css";
import {congratsStyle, viziLogoStyle} from "../../common/global/globalStyle/ImageStyle";
import {useLocation, useNavigate} from "react-router-dom";
import Http from "../../common/global/http/HttpRequest";
import {useTranslation} from "react-i18next";

function EmailVerfiication() {
    let navigate = useNavigate();
    const params = new URLSearchParams(useLocation().search);
    const {t} = useTranslation();

    const [loader, setLoader] = useState(false);

    const navigateToErrorPage = (statusCode, response) => {
        navigate({pathname: "/content/errorpage", search: `statusCode=${statusCode}&response=${response}`});
    };

    useEffect(() => { // Check for userID and token in the URL.

        const userId = params.get("userId");
        const token = params.get("token");
        if (userId && token) {
            updateUserVerifiedState(userId, token);
        } else {
            navigateToErrorPage(401, t("NO_ACCESS"));
        }
    }, []);


    // Handle the Verification of User Email.

    const updateUserVerifiedState = async (userId, token) => {

        setLoader(true);


        try {
            await Http.get(`user/updateVerifiedState/${userId}`, token);
            setLoader(false);
        } catch (error) {
            let responsearray = {
                ...error
            };
            console.log(responsearray.status);
          
            if (responsearray.request.status === 0) {
                setLoader(false);

                let statusCode = 502;
                let response = t("NETWORK_ERROR")
                navigate({pathname: "/content/errorpage", search: `statusCode=${statusCode}&response=${response}`});
            } else {
                setLoader(false);

                navigateToErrorPage(error.response.data.statusCode, t(`${
                    error.response.data.code
                }`));
            }
        }
    };

    return (
        <>
            <div className="ResetSuccessfull_container">
                <Grid container justifyContent="center">
                    <Grid item
                        xs={10}
                        sm={6}
                        md={4}
                        lg={4}
                        align="center">
                        <img src={viziLogo}
                            alt=""
                            style={viziLogoStyle}
                            elevation={0}/>

                        <div className="ResetSuccessfull_content">
                            <Card style={congratCardStyle}
                                elevation={0}>
                                {" "}
                                {
                                loader === false ? (
                                    <>
                                        <CardContent sx={1}
                                            md={6}
                                            lg={6}>
                                            <img src={congratLogo}
                                                alt=""
                                                style={congratsStyle}/>
                                            <Typography variant="h5"
                                                style={congratsHeading}>
                                                {
                                                t("verifyInfo")
                                            } </Typography>
                                        </CardContent>
                                    </>
                                ) : (
                                    <>
                                        <Box height={500}>
                                            <CircularProgress size={70}
                                                style={
                                                    {
                                                        color: " #38CC86",
                                                        marginTop: "30%"
                                                    }
                                                }/>
                                        </Box>
                                    </>
                                )
                            } </Card>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default EmailVerfiication;
