export const headingStyle = {
  fontWeight: "450",
  fontSize: "200%",
  // fontSize:"3rem",
  fontFamily: "CircularStd",
};

export const HeadingStyle2 = {
 
};

export const loginHeadingStyle = {
  fontWeight: "600",
  marginTop: "10%",
  fontSize: "200%",
  letterSpacing: "-0.5px",
  // fontSize:"3rem",
  fontFamily: "CircularStd",
};

export const InfoStyle = {
  width:"80%",
  fontWeight: "300",
  fontFamily: "CircularStd",
  textAlign:"center"
  
};

export const loginInfoStyle = {
  fontWeight: "450",
  marginTop: "2%",
  color: "#18191D",
  fontSize: "80%",

  // fontSize:"3rem",
  width: "60%",
  fontFamily: "CircularStd",
};

export const LoginPassInfoStyle = {
  fontWeight: "300",
  width: "100%",
  fontFamily: "CircularStd",
  fontSize: "82%",
  height: "0",
  padding: "2% 1% 2% 2%",
};

export const resetSucessInfoStyle = {
  fontWeight: "300",
  width: "90%",
  fontFamily: "CircularStd",
};

export const congratsHeading = {
  fontWeight: "600",

  // fontSize: "140%",
  // fontSize:"3rem",
  fontFamily: "CircularStd",
  width: "95%",
};

export const resetSucessHeading = {
  marginTop: "10%",
  fontWeight: "300",
  padding: "4px 4px 4px 4px",
  // fontSize: "96%",
  width: "10%",

  fontFamily: "CircularStd",
};

export const downloadAppStyle= {
  fontFamily: "CircularStd",
  fontWeight: "550",
  letterSpacing: "20%",
  width:"90%"
};

export const errorHeadingStyle = {
  fontWeight: "bold",
  color: "#4B5A5D",
  fontFamily: "CircularStd",
};

export const ErrorPageStyle2 = {
  marginTop: "-1%",
  fontSize: "190%",
  color: "#4B5A5D",
  fontWeight: "790",
  fontFamily: "CircularStd",
  marginBottom:"5%",
  width:"100%"
};


export const termsCondition = {
  color: "#4B5A5D",
  fontWeight: "400",
  fontFamily: "CircularStd",
  marginBottom:"1%",
  width:"100%",
  left:10,
  textAlign:"left",
  wordWrap: "break-word"
}



export const termsConditionheading = {
  color: "#0D1B1E",
  fontWeight: "700",
  fontFamily: "CircularStd",
  textAlign:"left",
  width:"100%",

  


}


export const termsConditionMainHeading = {

  color: "#0D1B1E",
  fontWeight: "700",
  fontFamily: "CircularStd",
  width:"100%",
  padding:"5px 0px 20px 5px"





}

// export const termsCondition = {
//   fontSize: "220%",
//   fontFamily: "CircularStd",
//   fontWeight: "700",
//   letterSpacing: "20%",
//   width:"100%",
//   padding:"4% 0% 3% 0%"

// };

export const faqheadingStyle = {
  fontSize: "230%",
  fontFamily: "CircularStd",
  fontWeight: "600",
  letterSpacing: "20%",
  marginBottom:"5%",
  width:"100%",
  marginTop:"2%"

};


export const faqheadingStyle2 = {
  fontFamily: "CircularStd",
  fontWeight: "600",
  width:"100%",
  textAlign:'left',
  padding:'2px 2px 2px 20px'


};

export const faqheadingStyle_2 = {
  fontFamily: "CircularStd",
  fontWeight: "600",
  letterSpacing: "20%",
  width:"100%",
  textAlign:"left",
  color:"#3A494B",
};

export const faqPageMainHeading = {
  fontFamily: "CircularStd",
  fontSize:'200%',
  fontWeight:'600',
  zIndex:100,
  marginBottom:'5%'
};


export const faqPageSubtitle ={
  fontSize:'120%',
  fontWeight:'400',
  textAlign:"left",
  fontFamily: "CircularStd",
  color:'#4B5A5D'
};

export const faqPageLink ={
  fontSize:'120%',
  fontWeight:'400',
  textAlign:"left",
  fontFamily: "CircularStd",
  color:'#596AFF',
  overflowWrap: 'break-word'
};

export const faqPageQuestion = {
  fontSize:'120%',
  fontWeight:'400',
  textAlign:"left",
  fontFamily: "CircularStd",
  color:'#4B5A5D',
  fontWeight:'600'
}

export const faqPageHeading = {
  fontSize:'140%',
  fontWeight:'700',
  textAlign:"left",
  fontFamily: "CircularStd",
  marginBottom:'1%'
}

export const termsAndConditionHeading = {
  fontSize:'120%',
  fontFamily: "CircularStd",
  fontWeight:"500",
  textAlign:'left'
}

export const termsAndConditionSubHeading = {
  fontSize:'100%',
  fontFamily: "CircularStd",
  fontWeight:'300',
  textAlign:'left'
}