import "../../common/reusableComponent/global.css";
import viziLogo from "../../common/global/images/vizilogo.png";
import React, { useEffect, useState } from "react";
import robotImage from "../../common/global/images/robotlogo.png";
import { errorHeadingStyle } from "../../common/global/globalStyle/TypographyStyle";
import { cardStyle } from "../../common/global/globalStyle/CardStyle";
import {
  robotlogoStyle,
  viziLogoStyle,
} from "../../common/global/globalStyle/ImageStyle";
import { useLocation } from "react-router-dom";

import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function Error404() {
  const [statusCode, setStatusCode] = useState({
    statusCode: "",
    response: "",
  });

  const params = new URLSearchParams(useLocation().search);
  const {t} = useTranslation();
  

  useEffect(() => {
    const statusCode = params.get(`statusCode`);
    const response = params.get(`response`);
    if (statusCode && response) {
      setStatusCode({
        statusCode: statusCode,
        response: response,
      });
    } else {
      setStatusCode({
        statusCode: 404,
        response:  t("PAGE_NOT_FOUND")
      });
    }
  }, []);

  return (
    <>
      <div className="ErrorPage_container">
        <Grid
          container
          justifyContent="center"
          elevation={0}
        >
          <Grid
            item
            xs={11}
            sm={10}
            md={10}
            lg={5}
            elevation={0}
            style={{}}
            align="center"
          >
            <img src={viziLogo} alt="" style={viziLogoStyle} />

            <div className="ErrorPage_content">
              <Card style={cardStyle} elevation={0}>
                <CardContent sx={1} md={10} lg={6}>
                  <img src={robotImage} alt="" style={robotlogoStyle} />
                  <Typography variant="h3" style={errorHeadingStyle}>
                    {statusCode.statusCode}
                  </Typography>
                  <Typography variant="h4" style={errorHeadingStyle}>
                    {statusCode.response}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Error404;
