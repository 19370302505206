import React from "react";
import { TextField } from "@material-ui/core";
import "./global.css";

function InputFieldPassword(props) {
  return (
    <>
      <TextField
        id={props.id}
        className={props.className ?? "inputRounded"}
        label={props.label}
        type={props.type}
        name={props.name}
        variant="outlined"
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        style={{
          width: "70%",
          height: "75px",
          // margin: "2% 2% 2% 2%",
          fontFamily: "CircularStd",
        }}
        maxLength={12}
        error={props.error}
        helperText={props.helperText}
        InputProps={props.InputProps}
        InputLabelProps={{
          style: {
            fontSize: '90%',
            backgroundColor: "#FFF",
            color: "#383838",
          },
        }}
        inputProps={{
          style: {
            width: "100%",
            fontSize: 12,
            fontWeight: "normal",
          },
        }}
        FormHelperTextProps={{
          style: {
            lineHeight: "10px",
            height: "5%",
            backgroundColor: "#FFF",
            marginBottom:"10000000000000000000px",

            fontSize: "10px",
          },
        }}
        onChange={props.onChange}
        onClick={props.onClick}
        onSelect={props.onSelect}
      ></TextField>
    </>
  );
}

export default InputFieldPassword;
