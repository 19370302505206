import React from "react";
import viziLogo from "../../common/global/images/vizilogo.png";
import congratLogo from "../../common/global/images/Congratulations.png";
import { CardContent, Card, Grid, Typography } from "@material-ui/core";
import { congratCardStyle } from "../../common/global/globalStyle/CardStyle";
import { congratsHeading } from "../../common/global/globalStyle/TypographyStyle";
import "../../common/reusableComponent/global.css";
import {
  congratsStyle,
  viziLogoStyle,
} from "../../common/global/globalStyle/ImageStyle";
import { useTranslation } from "react-i18next";

function AlreadyVerified() {
  const { t } = useTranslation();

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} md={4} lg={4} align="center">
          <img src={viziLogo} alt="" style={viziLogoStyle} elevation={0} />

          <Card style={congratCardStyle} elevation={0}>
            {" "}
            <>
              <CardContent sx={1} md={6} lg={6}>
                <img src={congratLogo} alt="" style={congratsStyle} />
                <Typography variant="h5" style={congratsHeading}>
                  {t("alreadyverifiedInfo")}{" "}
                </Typography>
              </CardContent>
            </>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default AlreadyVerified;
