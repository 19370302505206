import React , {useEffect}  from "react";
import { Grid, CardContent, Card, Typography } from "@material-ui/core";
import viziLogo from "../../common/global/images/vizilogo.png";
import { viziLogoTermsStyle } from "../../common/global/globalStyle/ImageStyle";
import { termsCardStyle } from "../../common/global/globalStyle/CardStyle";
import {
  termsCondition,
  termsConditionheading,
  termsConditionMainHeading,
} from "../../common/global/globalStyle/TypographyStyle";
import { data } from "../../common/staticData/termsandconditionData"
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {translationSpanish} from "../../common/global/localzation/LocalizationData"
import {translationEnglish} from "../../common/global/localzation/LocalizationData"
import LanguageDetector from "i18next-browser-languagedetector"
import {useLocation} from "react-router-dom";

function PrivacyPage() {


  const {t} = useTranslation();
  const search = useLocation().search;
  useEffect(() => {
      i18n.use(LanguageDetector).use(initReactI18next).init({
          resources: {
              en: {
                  translation: translationEnglish
              },
              sp: {
                  translation: translationSpanish
              }
          }
      });

      let language = new URLSearchParams(search).get("translation")
      i18n.changeLanguage(language)


  }, [])

  return (
      <>
          <Grid
        container
        justifyContent="center"
      >
        <Grid
          item
          xs={10}
          sm={8}
          md={10}
          lg={8}
          style={{}}
          align="center"
        >
          <img src={viziLogo} alt="" style={viziLogoTermsStyle} />

          <div className="ErrorPage_content">
            <Card style={termsCardStyle} elevation={0}>
              <CardContent>

                <Typography variant="h4"  style={termsConditionMainHeading}
                 
                 fontSize = {{
                   lg:2000,
                   md:10,
                   sx:20,
                 }}
                
                >
                {t("privacypage")}
                
                </Typography>

                <Typography variant="subtitle1" style={termsCondition}>
                  {t('privacyTitle')}
                </Typography>

                {[...Array(7)].map((el, index) => {
                  
                    return (
                      <>
                        <span key={index}>
                          <Typography
                            variant="subtitle1"
                            style={termsCondition}
                          >
                            {t(`privacyPara${index+1}`)}
                          </Typography>
                        </span>
                      </>
                    );
                })
              }

                <Typography variant="subtitle1" style={termsCondition}>
                  {data.content.info3}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </>
      
  )
}

export default PrivacyPage;
