import "../../common/reusableComponent/global.css";
import viziLogo from "../../common/global/images/vizilogo.png";
import React from "react";
import robotImage from "../../common/global/images/robotlogo.png";
import {
  errorHeadingStyle
} from "../../common/global/globalStyle/TypographyStyle";
import { cardStyle } from "../../common/global/globalStyle/CardStyle";
import {
  robotlogoStyle,
  viziLogoStyle,
} from "../../common/global/globalStyle/ImageStyle";

import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function PageNotFound() {

  const {t} = useTranslation();

  return (
    <>
      <div className="ErrorPage_container">
        <Grid
          container
          // spacing={24}
          justifyContent="center"
          // sm={12}
          //   style={{ minHeight: '100vh', maxWidth: '100%' }}
        >
          <Grid
            item
            xs={11}
            sm={10}
            md={10}
            lg={5}
            style={{
              height: "100vh",
            }}
            align="center"
            // justifyContent="center"
          >
            <img src={viziLogo} alt="" style={viziLogoStyle} />

            <div className="ErrorPage_content">
              <Card style={cardStyle} elevation={0}>
                <CardContent sx={1} md={10} lg={6}>
                  <img src={robotImage} alt="" style={robotlogoStyle} />
                  <Typography variant = "h4" style={errorHeadingStyle}>404</Typography>
                  <Typography variant = "h4" style={errorHeadingStyle}>
                   { t("PAGE_NOT_FOUND") }
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PageNotFound;
