export const cardStyle = {
  width: "100%",
  marginTop: "10%",
  borderRadius: "5%",
  background: "#FFFFFF",
  boxShadow: "0px 6px 16px rgba(19, 165, 121, 0.08)",
  marginBottom: "10%",
  borderCollapse: "collapse",
};

export const congratCardStyle = {
  width: "100%",
  marginTop: "10%",
  height: "400px",
  borderRadius: "5%",
  background: "#FFFFFF",
  boxShadow: "0px 6px 16px rgba(19, 165, 121, 0.08)",
  marginBottom: "10%",
  borderCollapse: "collapse",
};

export const downloadappCardStyle = {
  width: "100%",
  marginTop: "1%",
};

export const termsCardStyle = {
  width: "100%",
  marginTop: "1.2%",
  borderRadius: "5%",
  marginBottom: "10%",
};
