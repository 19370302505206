import ButtonComponent from "../../common/reusableComponent/ButtonComponent";
import viziLogo from "../../common/global/images/vizilogo.png";
import "../../common/reusableComponent/global.css";
import InputField from "../../common/reusableComponent/InputFieldComponent";
import React, { useEffect, useState } from "react";
import {
  CardContent,
  Card,
  Grid,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { cardStyle } from "../../common/global/globalStyle/CardStyle";
import { loginHeadingStyle } from "../../common/global/globalStyle/TypographyStyle";
import { loginInfoStyle } from "../../common/global/globalStyle/TypographyStyle";
import { viziLogoStyle } from "../../common/global/globalStyle/ImageStyle";
import PasswordStrengthMeter from "../../common/global/passwordStrengthtracker/Passwordtracker";
import {
  buttonOnActive,
  buttonNonActive,
} from "../../common/global/globalStyle/ButtonStyle";
import Http from "../../common/global/http/HttpRequest";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordInfoComponent from "../../common/reusableComponent/passwordInfoComponent";
import { useTranslation } from "react-i18next";
import InputFieldPassword from "../../common/reusableComponent/InputFieldPassword";
import DropDownComponent from "../../common/reusableComponent/Inputdropdowns";
import schema from "../../common/global/schema/Schemavalidation";
import { appRoute } from "../../common/route/routeStatic";

export default function LoginPage() {
  const [touched, setTouched] = useState({
    idValue: false,
    idTypeId: false,
    password: false,
  });

  const [user, setUser] = useState({
    idValue: "",
    idTypeId: "",
    password: "",
  });

  const [error, setError] = useState({
    idValue: "",
    idTypeId: "",
    password: "",
  });

  const [userDetails, setUserDetails] = useState(null);
  const search = useLocation().search;
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  let navigate = useNavigate();
  const [showeyepass, setShowEyePass] = useState(false);
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const userId = new URLSearchParams(search).get("userId");
    const tokenLocal = new URLSearchParams(search).get("token");
    setToken(tokenLocal);
    setUserId(userId);
    if (userId && tokenLocal) {
      getUserDetails(userId, tokenLocal);
    } else {
      navigateToErrorPage(401, t("NO_ACCESS"));
    }
  }, []);

  const navigateToErrorPage = (statusCode, response) => {
    navigate({
      pathname: `/content/errorpage`,
      search: `statusCode=${statusCode}&response=${response}`,
    });
  };

  const getUserDetails = async (userId, token) => {
    setLoader(true);
    try {
      let response = await Http.get(`user/mail/${userId}`, token);
      if (response) {
        setUserDetails(response.data);
      }

      if (response.data.isVerified) {
        navigate(appRoute.alreadyVerified);
      }

      setLoader(false);
    } catch (error) {
      handleApiCatch(error);
    }
  };

  const handleChange = (event) => {
    const data = { ...user };
    data[event.target.name] = event.target.value;
    setUser(data);
    formValidation(data);
  };

  const handleApiCatch = (error) => {
    let responsearray = { ...error };
    if (responsearray.request.status === 0) {
      let statusCode = 502;
      let response = t("NETWORK_ERROR");
      navigate({
        pathname: "/content/errorpage",
        search: `statusCode=${statusCode}&response=${response}`,
      });
      setLoader(false);
    } else {
      navigateToErrorPage(
        error.response.data.statusCode,
        t(`${error.response.data.code}`)
      );
      setLoader(false);
    }
  };

  const formValidation = (data) => {
    let resultError = schema.validateLoginForm(data);
    if (resultError.error) {
      const errorData = {};
      for (let item of resultError.error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
        setError(errorData);
      }
    } else {
      setError("");
    }
  };

  const onSubmit = async () => {
    let userlogin = {
      password: user.password,
      idDetails: {
        idValue: user.idValue,
        idTypeId: user.idTypeId,
        imageUrl: null,
      },
    };
    setLoader(true);
    try {
      let response = await  Http.patch(
        `user/updateOperatorDetails/${userId}`,
        userlogin,
        token
      );
      if (response) {
        navigate({
          pathname: `/content/downloadapp`,
          search: ``,
        });
        setLoader(false);
      }
    } catch (error) {
      handleApiCatch(error);
    }
  };

  const handleValidationOnBlur = (event) => {
    const { name, value } = event.target;
    const touchedField = { ...touched };
    touchedField[name] = true;
    setTouched(touchedField);

    const data = { ...user };
    data[name] = value;
    setUser(data);
    formValidation(data);
  };

  function renderValue(option) {
    if (option == null) {
      return <span>{t("idType")}</span>;
    }
    return <span>{option.label}</span>;
  }

  const handleIdTypeChange = (event) => {
    const data = { ...user };
    data["idTypeId"] = event;
    setUser(data);

    let resultError = schema.validateLoginForm(data);
    if (resultError.error) {
      const errorData = {};
      for (let item of resultError.error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
        setError(errorData);
      }
    } else {
      setError("");
    }
  };

  const handleValidationForIdtype = (event) => {
    if (event === false) {
      // const touchedField = { ...touched };
      // touchedField["idTypeId"] = true;
      // setTouched(touchedField);
      let resultError = schema.validateLoginForm(user);
      if (resultError.error) {
        const errorData = {};
        for (let item of resultError.error.details) {
          const name = item.path[0];
          const message = item.message;
          errorData[name] = message;
          setError(errorData);
        }
      } else {
        setError("");
      }
    }
  };

  return (
    <>
      <div className="LoginPage_container">
        <Grid
          container
          justifyContent="center"
          elevation={0}
          style={{
            width: "100%",
            height: "100vh",
            backgroundSize: "cover",
          }}
        >
          <Grid item xs={10} sm={8} md={6} lg={4} align="center" elevation={0}>
            <img src={viziLogo} alt="" style={viziLogoStyle} />

            <div className="LoginPage_content">
              <Card style={cardStyle} elevation={0}>
                <CardContent>
                  {loader === false ? (
                    <>
                      {" "}
                      <Typography style={loginHeadingStyle}>
                        {t("hello")}{" "}
                        {`${userDetails?.firstName} ${userDetails?.lastName}`}
                      </Typography>
                      <Typography style={loginInfoStyle}>
                        {t("logInfo")}
                      </Typography>
                      <DropDownComponent
                        renderValue={renderValue}
                        onChange={handleIdTypeChange}
                        name="IdTypeID"
                        error={touched.idTypeId === true && error.idTypeId}
                        errormessage={
                          touched.idTypeId === true && error.idTypeId
                        }
                        onBlur={(event) => {
                          handleValidationForIdtype(event);
                        }}
                      />
                      <InputField
                        id="idValue"
                        name="idValue"
                        label={t("idValue")}
                        type="text"
                        onChange={handleChange}
                        error={
                          touched.idValue === true &&
                          error.idValue === "notfound"
                            ? false
                            : touched.idValue === true &&
                              error.idValue !== "notfound"
                            ? error.idValue
                            : ""
                        }
                        helperText={
                          touched.idValue === true &&
                          error.idValue === "notfound"
                            ? ""
                            : touched.idValue === true &&
                              error.idValue !== "notfound"
                            ? error.idValue
                            : ""
                        }
                        // maxLength={user.idTypeId === 5 ? 8 : 12}
                        onBlur={(event) => {
                          handleValidationOnBlur(event);
                          setTouched({
                            ...touched,
                            idTypeId: true,
                            idValue: true,
                          });
                        }}
                      />
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      ></div>
                      <InputFieldPassword
                        id="password"
                        name="password"
                        label={t("passwordInput")}
                        style={{}}
                        onBlur={(event) => {
                          handleValidationOnBlur(event);
                        }}
                        type={showeyepass === true ? "type" : "password"}
                        error={
                          touched.password === true && error.password
                            ? true
                            : false
                        }
                        helperText={touched.password === true && error.password}
                        onChange={handleChange}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(event) => {
                                  setShowEyePass(false);
                                }}
                                onMouseDown={(event) => {
                                  setShowEyePass(true);
                                }}
                              >
                                {showeyepass ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {user.password.length > 0 ? (
                        <PasswordStrengthMeter password={user.password} />
                      ) : (
                        ""
                      )}
                      <br />
                      <PasswordInfoComponent />
                      <ButtonComponent
                        label={t("logbtnInfo")}
                        style={
                          user.idTypeId &&
                          user.password &&
                          error === "" &&
                          user.password.length >= 8 &&
                          user.idValue
                            ? buttonOnActive.buttonOnActive
                            : buttonNonActive.buttonNonActive
                        }
                        onClick={
                          user.idTypeId &&
                          user.password &&
                          error === "" &&
                          user.password.length >= 8 &&
                          user.idValue
                            ? onSubmit
                            : isDisabled
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Box height={500}>
                        <CircularProgress
                          size={70}
                          style={{
                            color: " #38CC86",
                            marginTop: "30%",
                          }}
                        />
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
