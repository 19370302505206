

const appStyle = {
  width: "18%",
  height: "5%",
  borderRadius: "1%",
  margin: "1% 2% 0% 0%",
};

const playStyle = {
  width: "18%",
  objectFit: "cover",
  margin: "1% 2% 0% 0%",
};

const iPhoneStyle = {
  
  width: "50%",
  objectFit: "contain",
};

 const viziLogoStyle= {
  marginTop: "10%",
  objectFit: "contain",
  width:"32%"
};

 const congratsStyle = {
  width: "15%",
  height: "15%",
  marginTop: "5%",
  marginBottom: "5%",
  
};

const robotlogoStyle = {
  width: "38%",
  height: "80%",
  marginTop: "4%",
};

const viziLogoTermsStyle =  {
  objectFit: "contain",
  marginTop:"6%"

}



const vizidownloadStyle = {
  marginTop:"4%",
  objectFit: "contain",
}


export {appStyle,viziLogoStyle, playStyle, iPhoneStyle,congratsStyle, robotlogoStyle, viziLogoTermsStyle , vizidownloadStyle};
