import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";

import "../../common/reusableComponent/global.css";
import ButtonComponent from "../../common/reusableComponent/ButtonComponent";
import React, { useState, useEffect } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import viziLogo from "../../common/global/images/vizilogo.png";
import { cardStyle } from "../../common/global/globalStyle/CardStyle";
import { headingStyle } from "../../common/global/globalStyle/TypographyStyle";
import { viziLogoStyle } from "../../common/global/globalStyle/ImageStyle";
import PasswordStrengthMeter from "../../common/global/passwordStrengthtracker/Passwordtracker";
import Http from "../../common/global/http/HttpRequest";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  buttonOnActive,
  buttonNonActive,
} from "../../common/global/globalStyle/ButtonStyle";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import PasswordInfoComponent from "../../common/reusableComponent/passwordInfoComponent";
import InputFieldPassword from "../../common/reusableComponent/InputFieldPassword";
import schema from "../../common/global/schema/Schemavalidation";
function ResetPassword() {
  const [showeyepass, setShowEyePass] = useState("");
  const [showeyeconfirm, setShowEyeConfirm] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");

  // State for check User Visit the Field or not.
  const [touched, setTouched] = useState({
    password: false,
    confirmpassword: false,
  });
  const [error, setError] = useState({
    password: "",
    confirmpassword: "",
  });
  const [appLang, setAppLang] = useState('en');

  const params = new URLSearchParams(useLocation().search);
  const [user, setUser] = useState({
    password: "",
    confirmpassword: "",
  });

  // Loader for handling condition of user waiting for Api response .

  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  let navigate = useNavigate();

  useEffect(() => {
    setAppLanguage();
    // Handling the Accessing of userID and token from the URL of webApp.
    const userId = params.get("userId");
    const token = params.get("token");
    if (userId && token) {
      setUserId(params.get("userId"));
      setToken(params.get("token"));
    } else {
      navigateToErrorPage(401, t("NO_ACCESS"));
    }
  }, []);

  const setAppLanguage = () => {
    if(t('language') === 'es') {
      setAppLang('es');
    } else {
      setAppLang('en');
    }
  }

  // Handle Error Page Navigation.

  const navigateToErrorPage = (statusCode, response) => {
    navigate({
      pathname: "/content/errorpage",
      search: `statusCode=${statusCode}&response=${response}`,
    });
  };

  // Handle the Overall Page Validation

  const formValidation = (data) => {
    let resultError = schema.validateResetForm(data);

    if (resultError.error) {
      const errorData = {};
      for (let item of resultError.error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
        setError(errorData);
      }
    } else {
      setError("");
    }
  };

  // Handle User Inputs on Fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    const data = { ...user };
    data[name] = value;
    setUser(data);
    formValidation(data);
  };

  // Handle Api error/Catch condition of Api response.

  const handleApiCatch = (error) => {
    let responsearray = { ...error };
    if (responsearray.request.status === 0) {
      let statusCode = 502;
      let response = t("NETWORK_ERROR")
      navigate({
        pathname: "/content/errorpage",
        search: `statusCode=${statusCode}&response=${response}`,
      });
      setLoader(false);
    } else {
      navigateToErrorPage(
        error.response.data.statusCode,
        t(`${error.response.data.code}`)

      );
      setLoader(false);
    }
  };


  // Handle User Reponse Submission and ResetPassword Form Success.

  const handleSubmit = async () => {
    let resetUrl = `user/resetPassword`;
    let userData = {
      newPassword: user.password,
      userId,
    };
    setLoader(true);
    try {
      let response = await Http.patch(resetUrl, userData, token, `lang=${appLang}`);
      if (response) {
        navigate({
          pathname: "/content/resetsuccessfull",
          search: `/?statusCode=${200}`,
        });

        setLoader(false);
      }
    } catch (error) {
      handleApiCatch(error);
    }
  };

  // Handling the User touched &&  User leave the field After touched .
  // and also handle validation .

  

  const handleValidationOnBlur = (event) => {
    const { name, value } = event.target;
    const touchedField = { ...touched };
    touchedField[name] = true;
    setTouched(touchedField);
    if (user.confirmpassword.length > 0 && user.password.length === 0) {
      setTouched({
        confirmpassword: true,
        password: true

      })
    }

    

    const data = { ...user };
    data[name] = value;
    setUser(data);
    formValidation(data);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={11}
          sm={10}
          md={6}
          lg={4}
          style={{
            width: "100%",
            borderRadius: "1000px",
          }}
          align="center"
        >
          <img src={viziLogo} alt="" style={viziLogoStyle} />

          <div className="ResetPassword_content">
            <Card style={cardStyle}>
              <CardContent>
                {loader === false ? (
                  <>
                    <Typography style={headingStyle}>{t("enter")}</Typography>
                    <Typography style={headingStyle}>
                      {t("password")}
                    </Typography>
                    <br />
                    <InputFieldPassword
                      label={t("passwordInput")}
                      type={showeyepass === true ? "type" : "password"}
                      name="password"
                      error={touched.password && error.password}
                      helperText={touched.password === true && error.password}
                      onChange={handleChange}
                      onBlur={(event) => {
                        handleValidationOnBlur(event);
                      }}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(event) => {
                                setShowEyePass(false);
                              }}
                              onMouseDown={(event) => {
                                setShowEyePass(true);
                              }}
                            >
                              {showeyepass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div
                      style={{
                        marginBottom: "8px",
                      }}
                    ></div>
                    <InputFieldPassword
                      label={t("confirmPassword")}
                      type={showeyeconfirm === true ? "type" : "password"}
                      name="confirmpassword"
                      onChange={handleChange}
                      
                      onBlur={(event) => {
                        handleValidationOnBlur(event);
                      }}
                      error={
                        touched.confirmpassword &&
                          user.password !== user.confirmpassword
                          ? true
                          : false ||
                          (touched.confirmpassword &&
                            user.confirmpassword.length === 0)
                      }
                      helperText={
                        touched.confirmpassword &&
                        `${user.confirmpassword.length === 0
                          ? `${t("confirmpasswordReq")}`
                          : error.confirmpassword === undefined
                            ? ""
                            : error.confirmpassword
                        } `
                      }
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(event) => {
                                setShowEyeConfirm(false);
                              }}
                              onMouseDown={(event) => {
                                setShowEyeConfirm(true);
                              }}
                            >
                              {showeyeconfirm ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {user.password.length > 0 ||
                      user.confirmpassword.length > 0 ? (
                      //  Password Strength Meter Component.

                      <PasswordStrengthMeter
                        password={user.password || user.resetpassword}
                      />
                    ) : (
                      ""
                    )}
                    <br />
                    {/*  Password Info Bar Component */}{" "}
                    <PasswordInfoComponent />
                    <ButtonComponent
                      label={t("btnInfo")}
                      style={
                        error === "" &&
                          user.password.length >= 8 &&
                          user.password !== "" &&
                          user.confirmpassword !== ""
                          ? buttonOnActive.buttonOnActive
                          : buttonNonActive.buttonNonActive
                      }
                      onClick={
                        error === "" &&
                          user.password.length >= 8 &&
                          user.password !== "" &&
                          user.confirmpassword !== "" &&
                          user.password === user.confirmpassword
                          ? handleSubmit
                          : isDisabled
                      }
                    />
                  </>
                ) : (
                  <>
                    <Box height={500}>
                      <CircularProgress
                        size={70}
                        style={{
                          color: " #38CC86",
                          marginTop: "30%",
                        }}
                      />
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;
