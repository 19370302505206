import React, { useEffect } from "react";
import { Grid, Card, CardContent, Typography, Link } from "@material-ui/core";
import vizilogo from "../../common/global/images/vizilogo.png";
import DropDown from "../../common/reusableComponent/DropdownComponent";
import { viziLogoTermsStyle } from "../../common/global/globalStyle/ImageStyle.jsx";
import {
  faqPageSubtitle,
  faqPageHeading,
  faqPageMainHeading,
  faqPageQuestion,
  faqPageLink
} from "../../common/global/globalStyle/TypographyStyle";
import "../../common/reusableComponent/global.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationSpanish } from "../../common/global/localzation/LocalizationData";
import { translationEnglish } from "../../common/global/localzation/LocalizationData";
import LanguageDetector from "i18next-browser-languagedetector";
import { useLocation } from "react-router-dom";
function FAQPage() {
  const { t } = useTranslation();
  const search = useLocation().search;

  const translationSchema = {
    list: [
      "faqPage.list.one.question",
      "faqPage.list.one.answer",
      "faqPage.list.two.question",
      "faqPage.list.two.answer",
      "faqPage.list.three.question",
      "faqPage.list.three.answer",
      "faqPage.list.four.question",
      "faqPage.list.four.answer",
      "faqPage.list.five.question",
      "faqPage.list.five.answer",
      "faqPage.list.six.question",
      "faqPage.list.six.answer",
      "faqPage.list.seven.question",
      "faqPage.list.seven.answer",
      "faqPage.list.eight.question",
      "faqPage.list.eight.answer",
      "faqPage.list.ninth.question",
      "faqPage.list.ninth.answer1",
      "faqPage.list.ninth.answer2",
      "faqPage.list.ninth.answer3",
    ],
    listTwo: [
      "faqPage.listTwo.one.question",
      "faqPage.listTwo.one.answer",
      "faqPage.listTwo.two.question",
      "faqPage.listTwo.two.answer",
      "faqPage.listTwo.three.question",
      "faqPage.listTwo.three.answer",
      "faqPage.listTwo.four.question",
      "faqPage.listTwo.four.answer",
      "faqPage.listTwo.five.question",
      "faqPage.listTwo.five.answer",
      "faqPage.listTwo.six.question",
      "faqPage.listTwo.six.answer",
      "faqPage.listTwo.seventh.question",
      "faqPage.listTwo.seventh.answer1",
      "faqPage.listTwo.seventh.answer2",
      "faqPage.listTwo.seventh.answer3",
    ],
  };

  useEffect(() => {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: translationEnglish,
          },
          sp: {
            translation: translationSpanish,
          },
        },
      });

    let language = new URLSearchParams(search).get("translation");
    i18n.changeLanguage(language);
  }, []);

  const isQuestion = (src) => {
    return src.includes('question');
  }

  const isAnswer = (src) => {
    return src === 'answer';
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11} sm={10} md={10} lg={9}  align="center">
          <img src={vizilogo} alt="" style={viziLogoTermsStyle} />
          <Card elevation={0} style = {{marginTop:'3%'}}>
            <CardContent>
                <div style = {{display:"flex", width:"80%", flexDirection:"column"}}>
              <Typography variant="h4" style = {faqPageMainHeading}>
                {t("frequentQuestion")}{" "}
              </Typography> 
              <Typography style = {faqPageHeading}>{t('faqPage.heading')}</Typography>
              {
                translationSchema.list.map((item) => {
                  return (
                    <> 
                      {
                        isQuestion(item) ? ( 
                          <Typography variant = "subtitle1" style = {faqPageQuestion}>{t(`${item}`)}</Typography>
                        )
                        : (
                          <>
                            {
                              (item.includes('answer') && !item.includes('faqPage.list.ninth')) && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                            {
                              item === 'faqPage.list.ninth.answer1' && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                            {
                              item === 'faqPage.list.ninth.answer2' && <Link variant = "subtitle1" target="_blank" rel="noreferrer" href={t(item)} style = {faqPageLink}>{t(`${item}`)}</Link>
                            }
                            {
                              item === 'faqPage.list.ninth.answer3' && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                          </>
                        )
                      }
                    </>
                  )
              })}
              <br />
              <br />
              <Typography style = {faqPageHeading}>{t('faqPage.headingTwo')}</Typography> 
              {
                translationSchema.listTwo.map((item) => {
                  return (
                    <> 
                      {
                        isQuestion(item) ? ( 
                          <Typography variant = "subtitle1" style = {faqPageQuestion}>{t(`${item}`)}</Typography>
                        )
                        : (
                          <>
                            {
                              (item.includes('answer') && !item.includes('faqPage.listTwo.seventh')) && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                            {
                              item === 'faqPage.listTwo.seventh.answer1' && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                            {
                              item === 'faqPage.listTwo.seventh.answer2' && <Link variant = "subtitle1" target="_blank" rel="noreferrer" href={t(item)} style = {faqPageLink}>{t(`${item}`)}</Link>
                            }
                            {
                              item === 'faqPage.listTwo.seventh.answer3' && <Typography variant = "subtitle1" style = {faqPageSubtitle}>{t(`${item}`)}</Typography>
                            }
                          </> 
                        )
                      }
                    </>
                  )
              })}
            
                </div>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default FAQPage;
