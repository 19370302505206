import React from "react";
import viziLogo from "../../common/global/images/vizilogo.png";
import congratLogo from "../../common/global/images/Congratulations.png";
import { CardContent, Card, Grid, Typography } from "@material-ui/core";
import {congratCardStyle  } from "../../common/global/globalStyle/CardStyle";
import {
  congratsHeading,
  resetSucessInfoStyle
} from "../../common/global/globalStyle/TypographyStyle";
import "../../common/reusableComponent/global.css";
import {
  congratsStyle,
  viziLogoStyle,
} from "../../common/global/globalStyle/ImageStyle";
import {useTranslation} from "react-i18next";


function ResetSuccessfull() {

  const {t} = useTranslation();

  return (
    <>
      <div className="ResetSuccessfull-container">
        <Grid
          container
          justifyContent="center"
        >
          <Grid
            item
            xs={10}
            sm={6}
            md={4}
            lg={4}
            align="center"
            elevation = {0}
           
          >
            <img
              src={viziLogo}
              alt=""
              style={viziLogoStyle}
              elevation={0}
            />

            <div className="ResetSuccessfull_content">
              <Card style={congratCardStyle} elevation={0}>
                <CardContent sx={1} md={6} lg={6}>
                  <img
                    src={congratLogo}
                    alt=""
                    style={congratsStyle}
                  />
                  <Typography variant="h5" style={congratsHeading}>
                    {t("congratsheading")}
                  </Typography>

                  <Typography variant="h5" style={congratsHeading}>
                  {t("congratsHeading2")}
                  </Typography>

                 
                  <Typography
                    variant="subtitle1"
                    style={resetSucessInfoStyle}
                  >
                    {t("congratsInfo")}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={resetSucessInfoStyle}
                  >
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ResetSuccessfull;
