export const appRoute = {

    login:"/auth/login",
    resetPassword:"/auth/resetpassword",
    downloadApp:"/content/downloadapp",
    emailVerification:"/content/emailverification",
    resetSuccessFull:"/content/resetsuccessfull",
    faq:"/help.vizi/faqpage",
    termsandConditions:"/help.vizi/termsandconditions",
    privacy:"/help.vizi/privacypage",
    notFound:"*",
    error:"/content/errorpage",
    alreadyVerified:"/content/alreadyverified",
    unsubscribeOperator: "/auth/unsubscribe",

}