import "./App.css";
import ResetPassword from "./components/pages/auth/ResetPassword";
import ResetSuccessfull from "./components/pages/content/ResetSuccessfull";
import LoginPage from "./components/pages/auth/LoginPage";
import DownloadApp from "./components/pages/content/Downloadapp";
import ErrorPage from "./components/pages/content/ErrorPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EmailVerfiication from "./components/pages/content/EmailVerfiication";
import PageNotFound from "./components/pages/content/NotFoundPage";
import FAQPage from "./components/pages/static/FAQ";
import TermsConditionPage from "./components/pages/static/TermsConditionPage";
import PrivacyPage from "./components/pages/static/PrivacyPage";
import { appRoute } from "./components/common/route/routeStatic";
import AlreadyVerified from "../src/components/pages/content/AlreadyVerified";
import UnsubscribeOperator from '../src/components/pages/auth/Unsubscribe';
// i18n import for initialize the localization in web forms.
// Not remove the i18n import.
import i18n from "../src/components/common/global/localzation/LocalizationMain"; 

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={appRoute.login} element={<LoginPage />} />
          <Route path={appRoute.error} element={<ErrorPage />} />
          <Route path={appRoute.downloadApp} element={<DownloadApp />} />
          <Route path={appRoute.resetPassword} element={<ResetPassword />} />
          <Route path={appRoute.resetSuccessFull} element={<ResetSuccessfull />} />
          <Route path={appRoute.emailVerification} element={<EmailVerfiication />} />
          <Route path={appRoute.faq} element={<FAQPage />} />
          <Route path={appRoute.termsandConditions} element={<TermsConditionPage />} />
          <Route path={appRoute.privacy} element={<PrivacyPage />} />
          <Route path={appRoute.notFound} element={<PageNotFound />} />
          <Route path={appRoute.alreadyVerified} element={<AlreadyVerified/>} />
          <Route path={appRoute.unsubscribeOperator} element={<UnsubscribeOperator/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
