export const translationSpanish = {
  language: "es",

  // TextField Regargind Text Part
  enter: "Ingresa tu nueva",
  password: "Contraseña",
  passwordInput: "Contraseña",
  confirmPassword: "Repetir contraseña",

  // Password Strength Info Messages.

  passwordInfo:
    "Una contraseña segura tiene 8 caracteres, entre minúsculas, mayúsculas y números.",
  btnInfo: "Iniciar sesión",
  passwordfillerror: "* El campo de contraseña está vacío",

  // Password Strength Messages.
  low: "Baja",
  medium: "MEDIA",
  high: "Alta",

  // Login Page Dynammic Data

  hello: "Hola",
  logInfo:
    "Ingresa tu Documento de Identidad y configura una contraseña para iniciar sesión en VIZI.",
  idValue: "Valor de identificación",
  logbtnInfo: "Iniciar sesión",
  idType: "tipo de identificación",
  downloadheading: "Descarga Vizi para empezar a trabajar en",
  establish: "tu establecimiento",

  // Email Verifications
  verifyInfo:
    "Verificamos tu correo electrónico, ahora puedes continuar con nuestro servicio.",

  // Reset SuccessFull Validation Message.

  congratsheading: "¡Felicidades!",
  congratsHeading2: "Su contraseña fue cambiada con éxito",
  congratsInfo:
    "Hemos enviado una prueba de cambio de contraseña a su correo electrónico registrado.",

  // Error Message Validations.

  dnierror: "* Introducir DNI válido",
  ceerror: "* Por favor ingrese C.E. válido",
  passporteerror: "* Por favor ingrese Pasaporte válido",
  idtypeerror: "* Se requiere tipo de identificación",
  idValuerror: "* Se requiere valor de identificación",
  validpassworderror: "* Se requiere contraseña válida",
  passworderror: "* Se requiere Contraseña",
  passwordminerror: "* La contraseña debe ser de 8 dígitos",
  confirmpassword: "* La contraseña debe ser la misma",
  confirmpasswordReq: "* Se requiere repetir la Contraseña",
  celenError: "* C.E debe tener como máximo 12 caracteres",
  dnilenError: "* El DNI debe ser de 8 Dígitos",
  passportlenError: "* El pasaporte debe tener como máximo 12 caracteres",

  // faq page

  frequentQuestion: "Preguntas frecuentes",
  questionCategory: "Categoría de pregunta",
  privacypage: "Política de privacidad",
  termspage: "Términos y condiciones",

  // already Verfied Text

  alreadyverifiedInfo: "Tu cuenta ya ha sido verificada",

  // BACKEND ERROR MESSAGES TRANSLATION STARTED

  NO_ACCESS: "No tienes acceso.",
  NETWORK_ERROR: "Error de red",

  SESSION_TIMEOUT_SEND_MAIL:
    "Hora de término de la sesión. Solicitar de nuevo para recibir un correo",
  PAGE_NOT_FOUND: "Página no encontrada !",

  SOME_ERROR_OCCURED: "Ocurrió Algún Error",
  SESSION_EXPIRED: "Sesión expirada. Vuelva a iniciar sesión para continuar.",
  INVALID_USERNAME_OR_PASSWORD: "Usuario o contraseña invalido",
  MAIL_NOT_SENT: "Correo no enviado",
  NOT_IMPLEMENTED: "No implementada",
  NOT_MODIFIED: "Datos no actualizados",
  ACTIVE_BOOKING_FOUND: "Tienes una Reserva en Progreso",
  PARKING_IS_FULL: "El estacionamiento está lleno",
  INVALID_QR_SCANNED: "Código QR escaneado no válido",
  NO_OF_FILES_EXCEEDED: "No Of Files Exceeded. Maximum Limit is 5.",
  EMAIL_ALREADY_EXISTS: "Número de archivos excedido. El límite máximo es 5.",
  EMAIL_ALREADY_EXISTS_PLEASE_SIGN_IN:
    "El ID de correo electrónico ya existe. Si es un usuario, intente iniciar sesión.",
  OPERATOR_INVALID_USER_TYPE_ID: "ID de tipo de usuario no válido",
  PROFILE_IMAGE_ALREADY_EXISTS: "La imagen de perfil ya existe",
  EMAIL_NOT_REGISTERED: "El correo electrónico no está registrado",
  MAIL_SENT_SUCCESSFULLY: "El correo enviado con éxito",
  INVALID_USER_TYPE_ID: "ID de tipo de usuario no válido",
  BAD_REQUEST: "Solicitud no válida",
  ID_NOT_FOUND: "Identificación no encontrada",
  ID_TYPE_NOT_FOUND: "Tipo de identificación no encontrado",
  OPERATING_SYSTEM_NOT_FOUND: "Sistema operativo no encontrado",
  BIKE_NOT_FOUND: "Bicicleta no encontrada",
  IMAGE_NOT_FOUND: "Imagen no encontrada",
  BIKE_TYPE_NOT_FOUND: "Tipo de bicicleta no encontrado",
  BLOOD_TYPE_NOT_FOUND: "Tipo de sangre no encontrado",
  ESTABLISHMENT_TYPE_NOT_FOUND: "Tipo de establecimiento no encontrado",
  ESTABLISHMENT_NOT_FOUND: "Establecimiento no encontrado",
  NO_SELECTED_ESTABLISHMENT_FOUND:
    "No se encontró ningún establecimiento seleccionado",
  ESTABLISHMENT_IMAGE_NOT_FOUND: "Imagen del establecimiento no encontrada",
  BOOKING_NOT_FOUND: "Reserva no encontrada",
  DEVICE_INFO_NOT_FOUND: "Información del dispositivo no encontrada",
  ID_DETAILS_NOT_FOUND: "Detalles de identificación no encontrados",
  OPERATOR_NOT_FOUND: "Operadora no encontrada",
  PROFILE_IMAGE_NOT_FOUND: "Imagen de perfil no encontrada",
  USER_NOT_FOUND: "Usuario no encontrada",
  USER_TYPE_NOT_FOUND: "Tipo de usuario no encontrado",
  EMAIL_NOT_FOUND: "El correo electrónico no encontra",

  // Privacy Paragraphs
  privacyTitle:
    "Protección de la privacidad en relación con el tratamiento de datos personales.",
  privacyPara1:
    "El Usuario garantiza proporcionar datos personales correctos y completos durante el Pedido a través de la aplicación, sitio web o al registrarse para otros servicios ofrecidos en el Sitio Web / App.",
  privacyPara2:
    "VIZI se compromete a no revelar los datos personales a terceros. Estos son confidenciales. Serán utilizados por sus servicios internos únicamente para brindar el procesamiento de reservas, con fines de comunicación o como parte de la personalización del sitio según las preferencias de los miembros.",
  privacyPara3:
    "En caso de cesión o uso por parte de terceros de datos personales, VIZI se compromete a informar previamente al Usuario para que pueda ejercer su derecho de oposición. VIZI también puede proporcionar estadísticas consolidadas sobre sus usuarios, reservas, patrones comerciales e información en el Sitio a terceros de confianza, pero estas estadísticas no contendrán ningún dato personal. Este artículo no podrá, sin embargo, impedir la cesión o transferencia de actividades a un tercero.",
  privacyPara4:
    "De acuerdo con el Reglamento General de Protección de Datos (GDPR), el Usuario tiene derecho de acceso, control, rectificación y, posiblemente, eliminación completa de sus datos personales. Cualquier solicitud en este sentido puede gestionarse a través de la cuenta de cliente del usuario, o a través del Servicio de Atención al Cliente de VIZI por correo electrónico a ayuda@vizispot.com con prueba de identidad (fotocopia de cédula de identidad). Sin embargo, de conformidad con la ley, VIZI se reserva el derecho de retener los datos de los usuarios necesarios para sus operaciones y el control de estos por parte de instituciones autorizadas por un período de diez (10) años.",
  privacyPara5:
    "La solicitud de supresión de datos personales no puede solicitarse mientras esté en curso o por venir un contrato o servicio. Esta solicitud no se asociará automáticamente a una solicitud de cierre del contrato, debiendo cerrarse previamente de acuerdo o servicio que tenga el usuario o cliente con VIZI.",
  privacyPara6:
    "El Sitio Web utiliza el uso de cookies. La cookie es un archivo de computadora, almacenado en el disco duro de la microcomputadora del usuario, destinado a señalar una visita anterior del Usuario o el usuario al Sitio. Además, VIZI utiliza cookies para personalizar el servicio que ofrece a los usuarios. El usuario conserva la posibilidad de rechazar las cookies configurando su navegador de Internet. Pierde la capacidad de personalizar el servicio que VIZI le brinda.",
  privacyPara7:
    'Algunas páginas web del sitio a veces pueden contener imágenes electrónicas o "web beacons", que permiten contar el número de visitantes de la página. Estos web beacons pueden ser utilizados por algunos de los socios de VIZI, especialmente para medir y mejorar la efectividad de ciertas acciones. La información obtenida a través de estas etiquetas puede simplemente recopilar estadísticas sobre el uso de ciertas páginas del Sitio, con el fin de brindar un mejor servicio a los Miembros.',






  //FAQ PAGE.

  faqPage: {
    heading: `VIZI-CLETEROS`,
    list: {
      one: {
        question: `1.  ¿Qué necesito para abrir una cuenta?`,
        answer: `Es necesario colocar tus datos personales, tomes una foto a tu Documento de
      Identidad y una foto selfie. Además, deberás colocar los datos de tu bicicleta
      incluyendo la marca, el modelo, número de serie y una foto de ella.
      Finalmente, completa tu perfil con tu tipo de sangre y datos de contacto.`},
      two: {
        question: `2. ¿Por qué me piden mi tipo de sangre y datos de contacto?`,
        answer: `Es por tu seguridad; ya que en caso tengas algún accidente, esa información es muy
      relevante para que puedas recibir primeros auxilios y alguien cercano pueda
      responder y apoyarte.`},
      three: {
        question: `3. ¿Puedo registrar más de 1 bicicleta?`,
        answer: `Si. Solo recuerda colocar los datos y la foto correcta de cada una :)`
      },
      four: {
        question: `4. ¿Usar la App cuesta?`,
        answer: `No. Usar la app no cuesta. En un futuro, habrán estacionamientos pagados y por
      medio de la app, podrás recargar tu cuenta para usarlos.`},
      five: {
        question: `5. ¿Puedo ver todos los parqueos de Lima y provincias?`,
        answer: `Si. Podrás ver todos los parqueos disponibles, sean gratuitos o pagados en Lima y
      provincias.`},
      six: {
        question: `6. ¿Puedo usar gratis todos los parqueos?`,
        answer: `No. Hay algunos parqueos gratuitos y otros pagados. Podrás ver el detalle de cada
      parqueo en el app, incluido el precio.`},
      seven: {
        question: `7. ¿Puedo contactar al anfitrión del Vizi-Parqueo?`,
        answer: `Por ahora no. Más adelante, podrás contactar al anfitrión por medio del App.`
      },
      eight: {
        question: `8. ¿Puedo ser usuario de Vizi-Cleteros y también ofrecer mi parqueo usando ,Vizi-Negocio?`,
        answer: `Si, si puedes. Solo deberás crear una cuenta en ambas aplicaciones.`
      },
      ninth: {
        question: '9. ¿Cómo puedo darle de baja a mi cuenta?',
        answer1: `En caso desees anular tu cuenta y ya no volver a usar tu app, deberás solicitarlo por medio de este formulario:`,
        answer2: `https://forms.gle/CeGJo3k6FHVVyZb77`,
        answer3: `Nuestro equipo verificará que no tengas ningún saldo pendiente y que tú bicicleta no esté en ningún Vizi Parqueo. El tiempo máximo para anular tu cuenta será de 48 horas.`,
      },
    },
    headingTwo: `VIZI-PARQUEO`,

    listTwo: {
      one: {
        question: `1. ¿Qué necesito para abrir una cuenta?`,
        answer: `Primero debes registrarte como persona. Colocar tus datos personales completos,
      crear tu usuario y contraseña.`},
      two: {
        question: `2. ¿Cómo hago para registrar un estacionamiento?`,
        answer: `Es simple, registra un estacionamiento colocando el nombre comercial, la dirección,
      la cantidad de espacios disponibles el precio por hora y el horario de atención. Es
      importante que agregues una buena foto para que las personas lo puedan identificar
      con facilidad.`},
      three: {
        question: `3. ¿Puedo tener más de un estacionamiento?`,
        answer: `Si. Bajo tu mismo usuario, podrás registrar 1 o más estacionamientos. Cada uno
      tiene su propio panel de gestión.`},
      four: {
        question: `4. ¿Cómo gesitono los espacios?`,
        answer: `Cada vez que una persona llegue a tu estacionamiento, deberá escanear el QR de
      tu aplicación. En automático, el App va a registrar un ingreso, y por consiguiente,
      reducirá los espacios disponibles que se les muestra a los Vizi-cleteros.`},
      five: {
        question: `5. ¿Puedo cobrar por el estacionamiento?`,
        answer: `Si, si puedes. Deberás registrar el precio al momento de configurar tu
      estacionamiento. Recuerda que el precio debe ser competitivo para que los
      vizi-cleteros se vean atraídos a usar tu parqueo.`},
      six: {
        question: `6. ¿Cómo veo el historial de todas las personas que usaron mi estacionamiento?`,
        answer: `Al ingresar al App, en la pantalla de inicio, podrás ver los espacios disponibles, los
      ocupados y los reservados. Podrás ver también los principales datos de las
      personas que han hecho uso del espacio.`},
      seventh: {
        question: '7. ¿Cómo puedo darle de baja a mi cuenta?',
        answer1: `En caso desees anular tu cuenta y ya no volver a usar tu app, deberás solicitarlo por medio de este formulario:`,
        answer2: `https://forms.gle/CeGJo3k6FHVVyZb77`,
        answer3: `Nuestro equipo verificará que no tengas ningún saldo a favor pendiente y ninguna bicicleta se encuentre en tu parqueo. tú bicicleta no esté en ningún Vizi Parqueo. El tiempo máximos para anular tu cuenta será de 48 horas.`,
      }
    },
  }

};

export const translationEnglish = {

  language: "en",

  // TextField Regargind Text Part

  enter: "Enter your new",
  password: "Password",
  passwordInput: "Password",
  confirmPassword: "Repeat password",

  // Password Strength Info Messages.

  passwordInfo:
    "A strong password is 8 characters long, between lowercase, uppercase, and numbers.",

  // Button Info Messages.

  btnInfo: "Update password",

  // Password Strength Messages.

  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH",

  // Login Page Dynammic Data

  hello: "Hello",
  idValue: "ID Value",
  idType: "ID Type",

  // User Login Form
  logInfo: "Enter your Identity Document and set a password to log into VIZI.",
  logbtnInfo: "Log in",

  // Download App
  downloadheading: "Download Vizi to start working in",
  establish: "your establishment",

  // Email Verifications

  verifyInfo: "We verify your email, Now you can continue with our service.",

  // Reset SuccessFull Validation Message.

  congratsheading: "Congratulation!",
  congratsHeading2: "Your Password was  changed successfully",
  congratsInfo:
    "We have sent a proof of password change to your registered email.",

  // Error Message Validations.

  dnierror: "* Please enter valid DNI ",
  ceerror: "* Please enter valid C.E.",
  passporteerror: "* Please enter valid Pasaporte ",
  idtypeerror: "* ID Type is required",
  idValuerror: "* ID Value is required",
  validpassworderror: "* Valid password is required",
  passworderror: "* Password is required",
  passwordminerror: "* Password must have 8 characters",
  confirmpassword: " * Password needs to be same",
  confirmpasswordReq: " * Repeat Password is required",
  passwordfillerror: "* Password field is empty ",
  celenError: "* C.E must be at most 12 characters",
  dnilenError: "* DNI must be 8 Digit",
  passportlenError: "* Passporte must be at most 12 characters",

  // faq page

  frequentQuestion: "Frequent Asked questions",
  questionCategory: "Question Category",
  privacypage: "Privacy Policy",
  termspage: " Terms and Conditions",

  // already Verfied Text

  alreadyverifiedInfo: "Your account has been already verified",

  // BACKEND ERROR MESSAGES TRANSLATION STARTED

  NO_ACCESS: "You dont have access.",
  NETWORK_ERROR: "Network Error",
  PAGE_NOT_FOUND: "Page Not Found !",
  SESSION_TIMEOUT_SEND_MAIL: "Session timeout. Request again to get a mail",
  SOME_ERROR_OCCURED: "Some Error Occured",
  SESSION_EXPIRED: "Session Expired. Login again to continue.",
  INVALID_USERNAME_OR_PASSWORD: "Invalid Username Or Password",
  MAIL_NOT_SENT: "Mail Not Sent",
  NOT_IMPLEMENTED: "Not Implemented",
  NOT_MODIFIED: "Data Not Updated",
  ACTIVE_BOOKING_FOUND: "You have a Booking in Progress",
  PARKING_IS_FULL: "Parking is Full",
  INVALID_QR_SCANNED: "Invalid QR Code Scanned",
  NO_OF_FILES_EXCEEDED: "No Of Files Exceeded. Maximum Limit is 5.",
  EMAIL_ALREADY_EXISTS: "Email Already Exists",
  EMAIL_ALREADY_EXISTS_PLEASE_SIGN_IN:
    "Email Id Already Exists. If you are a user, Please Try Signing In.",
  OPERATOR_INVALID_USER_TYPE_ID: "Invalid User Type Id",
  PROFILE_IMAGE_ALREADY_EXISTS: "Profile Image Already Exists",
  EMAIL_NOT_REGISTERED: "Email Is Not Registered",
  MAIL_SENT_SUCCESSFULLY: "Mail Sent Successfully",
  INVALID_USER_TYPE_ID: "Invalid User Type Id",
  BAD_REQUEST: "Invalid Request",
  ID_NOT_FOUND: "Id Not Found",
  ID_TYPE_NOT_FOUND: "Id Type Not Found",
  OPERATING_SYSTEM_NOT_FOUND: "Operating System Not Found",
  BIKE_NOT_FOUND: "Bike Not Found",
  IMAGE_NOT_FOUND: "Image Not Found",
  BIKE_TYPE_NOT_FOUND: "Bike Type Not Found",
  BLOOD_TYPE_NOT_FOUND: "Blood Type Not Found",
  ESTABLISHMENT_TYPE_NOT_FOUND: "Establishment Type Not Found",
  ESTABLISHMENT_NOT_FOUND: "Establishment Not Found",
  NO_SELECTED_ESTABLISHMENT_FOUND: "No Selected Establishment Found",
  ESTABLISHMENT_IMAGE_NOT_FOUND: "Establishment Image Not Found",
  BOOKING_NOT_FOUND: "Booking Not Found",
  DEVICE_INFO_NOT_FOUND: "Device Info Not Found",
  ID_DETAILS_NOT_FOUND: "Id Details Not Found",
  OPERATOR_NOT_FOUND: "Operator Not Found",
  PROFILE_IMAGE_NOT_FOUND: "Profile Image Not Found",
  USER_NOT_FOUND: "User Not Found",
  USER_TYPE_NOT_FOUND: "User Type Not Found",
  EMAIL_NOT_FOUND: "Email Not Found",
  // BACKEND ERROR MESSAGES TRANSLATION ENDED

  // Privacy Paragraphs
  privacyTitle:
    "Protection of privacy in relation to the processing of personal data.",
  privacyPara1:
    "The User guarantees to provide correct and complete personal data during the Order through the application, website or when registering for other services offered on the Website / App.",
  privacyPara2:
    "VIZI undertakes not to disclose personal data to third parties. These are confidential. They will be used by your internal services only to provide reservation processing, for communication purposes or as part of the customization of the site according to the preferences of the members.",
  privacyPara3:
    "In the event of transfer or use by third parties of personal data, VIZI undertakes to inform the User in advance so that they can exercise their right to object. VIZI may also provide aggregated statistics about its users, bookings, trading patterns, and information on the Site to trusted third parties, but these statistics will not contain any personal data. This article may not, however, prevent the assignment or transfer of activities to a third party.",
  privacyPara4:
    "In accordance with the General Data Protection Regulation (GDPR), the User has the right of access, control, rectification and, possibly, complete deletion of their personal data. Any request in this regard can be managed through the user's customer account, or through VIZI's Customer Service by email to help@vizispot.com with proof of identity (photocopy of identity card). However, in accordance with the law, VIZI reserves the right to retain the user data necessary for its operations and the control of these by authorized institutions for a period of ten (10) years.",
  privacyPara5:
    "The request for deletion of personal data cannot be requested while a contract or service is in progress or to come. This request will not be automatically associated with a request to close the contract, and must be previously closed according to the agreement or service that the user or client has with VIZI.",
  privacyPara6:
    "The Website uses the use of cookies. The cookie is a computer file, stored on the hard drive of the user's microcomputer, intended to signal a previous visit of the User or the user to the Site. In addition, VIZI uses cookies to personalize the service it offers to users. The user retains the possibility of rejecting cookies by configuring their Internet browser. You lose the ability to personalize the service that VIZI provides you.",
  privacyPara7:
    "Some web pages of the site may sometimes contain electronic images or 'web beacons', which allow the number of visitors to the page to be counted. These web beacons may be used by some of VIZI's partners, especially to measure and improve the effectiveness of certain actions. The information obtained through these tags may simply collect statistics on the use of certain pages of the Site, in order to provide a better service to Members.",



  //FAQ PAGE 

  faqPage: {
    heading: `VIZI-BIKER`,
    list: {
      one: {
        question: `1.  What do I need to open an account?`,
        answer: `
      It is necessary to place your personal data, take a photo to your Document of
      Identity and a selfie photo. In addition, you must place the data of your bicycle
      including the make, model, serial number and a photo of it.
      Finally, fill in your profile with your blood type and contact details.`},
      two: {
        question: `2. Why am I being asked for my blood type and contact details?`,
        answer: `It's for your safety; since in case you have an accident, that information is very
      relevant so that you can receive first aid and someone close to you can
      respond and support you.`},
      three: {
        question: `3. Can I register more than 1 bike?`,
        answer: `Yes. Just remember to place the data and the correct photo of each :)`
      },
      four: {
        question: `4. Does using the App cost?`,
        answer: `No. Using the app doesn't cost. In the future, there will be paid parking and for
      by means of the app, you will be able to top up your account to use them.`},
      five: {
        question: `5. Can I see all the parking lots in Lima and provinces?`,
        answer: `Yes. You can see all the parking available, whether free or paid in Lima and
      provinces.`},
      six: {
        question: `6. Can I use all the parking lots for free?`,
        answer: `No. There are some free parking lots and others paid. You will be able to see the detail of each
      parking in the app, including price.`},
      seven: {
        question: `7. Can I contact the host of Vizi-Parqueo?`,
        answer: `Not yet. Later, you can contact the host through the App.`
      },
      eight: {
        question: `8. Can I be a user of Vizi-Cleteros and also offer my parking using,Vizi-Business?`,
        answer: `Yes, yes you can. You'll only need to create one account in both apps.`
      },
      ninth: {
        question: '9. How can I cancel my account?',
        answer1: `In case you want to cancel your account and no longer use your app, you must request it through this form:`,
        answer2: `https://forms.gle/CeGJo3k6FHVVyZb77`,
        answer3: `Our team will verify that you do not have any pending balance and that your bicycle is not in any Vizi Parking. The maximum time to cancel your account will be 48 hours.`,
      },
    },

    headingTwo: `VIZI-PARKING`,
    listTwo: {
      one: {
        question: `1. What do I need to open an account?`,
        answer: `You must first register as a person. Place your complete personal data,
      create your username and password.`},
      two: {
        question: `2. How do I register a parking lot?`,
        answer: `It's simple, register a parking lot by placing the business name, address,
      the number of spaces available, the hourly price and the hours of operation. Is
      important that you add a good photo so that people can identify it
      with ease.`},
      three: {
        question: `3. Can I have more than one parking lot?`,
        answer: `Yes. Under your same username, you will be able to register 1 or more parking lots. Apiece
      has its own management panel.`},
      four: {
        question: `4. How do I manage spaces?`,
        answer: `Each time a person arrives at your parking lot, they will need to scan the QR of
      your app. In automatic, the App will register an income, and therefore,
      reduce the available spaces shown to the Vizi-cleteros.`},
      five: {
        question: `5. Can I charge for parking?`,
        answer: `Yes, yes you can. You will need to record the price when setting up your
      parking. Remember that the price must be competitive so that the
      vizi-cleteros are attracted to use your parking.`},
      six: {
        question: `6. How do I see the history of all the people who used my parking lot?`,
        answer: `When entering the App, on the home screen, you will be able to see the available spaces, the
      busy and reserved. You can also see the main data of the
      people who have made use of space.`},
      seventh: {
        question: '7. How can I cancel my account?',
        answer1: `In case you want to cancel your account and no longer use your app, you must request it through this form:`,
        answer2: `https://forms.gle/CeGJo3k6FHVVyZb77`,
        answer3: `Our team will verify that you do not have any pending credit balance and that no bicycle is in your parking lot. your bicycle is not in any Vizi Parking. The maximum time to cancel your account will be 48 hours.`,
      }
    }
  }

}