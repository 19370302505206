import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {translationSpanish} from "./LocalizationData"
import {translationEnglish} from "./LocalizationData"
import LanguageDetector from "i18next-browser-languagedetector"


i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: translationEnglish
     },
     sp: {
       translation: translationSpanish
     }
   }
 });
 

const locale = navigator.language;

if(locale === "es"){
  i18n.changeLanguage("sp")
}

else {
  i18n.changeLanguage("en")

}

export default i18n;
