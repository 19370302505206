export const data = {
    "content": {
        "info": "Protección de la privacidad en relación con el tratamiento de datos personales.",
        "para1": "El Usuario garantiza proporcionar datos personales correctos y completos durante el Pedido a través de la aplicación, sitio web o al registrarse para otros servicios ofrecidos en el Sitio Web / App.",
        "para2": "VIZI se compromete a no revelar los datos personales a terceros. Estos son confidenciales. Serán utilizados por sus servicios internos únicamente para brindar el procesamiento de reservas, con fines de comunicación o como parte de la personalización del sitio según las preferencias de los miembros.",
        "para3": "En caso de cesión o uso por parte de terceros de datos personales, VIZI se compromete a informar previamente al Usuario para que pueda ejercer su derecho de oposición. VIZI también puede proporcionar estadísticas consolidadas sobre sus usuarios, reservas, patrones comerciales e información en el Sitio a terceros de confianza, pero estas estadísticas no contendrán ningún dato personal. Este artículo no podrá, sin embargo, impedir la cesión o transferencia de actividades a un tercero.",
        "para4": "De acuerdo con el Reglamento General de Protección de Datos (GDPR), el Usuario tiene derecho de acceso, control, rectificación y, posiblemente, eliminación completa de sus datos personales. Cualquier solicitud en este sentido puede gestionarse a través de la cuenta de cliente del usuario, o a través del Servicio de Atención al Cliente de VIZI por correo electrónico a <a>ayuda@vizispot.com<a> con prueba de identidad (fotocopia de cédula de identidad). Sin embargo, de conformidad con la ley, VIZI se reserva el derecho de retener los datos de los usuarios necesarios para sus operaciones y el control de estos por parte de instituciones autorizadas por un período de diez (10) años.",
        "para5": "La solicitud de supresión de datos personales no puede solicitarse mientras esté en curso o por venir un contrato o servicio. Esta solicitud no se asociará automáticamente a una solicitud de cierre del contrato, debiendo cerrarse previamente de acuerdo o servicio que tenga el usuario o cliente con VIZI.",
        "para6": "El Sitio Web utiliza el uso de cookies. La cookie es un archivo de computadora, almacenado en el disco duro de la microcomputadora del usuario, destinado a señalar una visita anterior del Usuario o el usuario al Sitio. Además, VIZI utiliza cookies para personalizar el servicio que ofrece a los usuarios. El usuario conserva la posibilidad de rechazar las cookies configurando su navegador de Internet. Pierde la capacidad de personalizar el servicio que VIZI le brinda.",
        "para7": "Algunas páginas web del sitio a veces pueden contener imágenes electrónicas o 'web beacons', que permiten contar el número de visitantes de la página. Estos web beacons pueden ser utilizados por algunos de los socios de VIZI, especialmente para medir y mejorar la efectividad de ciertas acciones. La información obtenida a través de estas etiquetas puede simplemente recopilar estadísticas sobre el uso de ciertas páginas del Sitio, con el fin de brindar un mejor servicio a los Miembros.",
    },
}


export const htmlDataforSpanish = `
Términos y condiciones Vizi <br /> 
Condiciones generales <br />  
La compañía peruana Smart Mobility Solutions S.A.C, en adelante VIZI, registrada en Perú, con el número de RUC:
20608273426, cuya oficina está en la dirección Av. Rivera Navarrete 548, oficina 802, San Isidro, Perú, ofrece un servicio de
disponibilización, visualización y separación de espacios para ciclistas e interesados en permitir parquear vehículos menores.
Todo usuario que utilice el servicio para parquear sus vehículos será denominado “USUARIO” de ahora en adelante, y todo
interesado en disponibilizar sus espacio para que puedan parquear vehículos menores será denominado “EL SOCIO” de ahora
en adelante. <br /> 
USUARIO y SOCIO declaran ser consciente de la necesidad de poseer un teléfono móvil actualizado y que contenga capacidades
que permitan utilizar la aplicación de VIZI con el objetivo de poder utilizar todas las funcionalidades del servicio tales y no
limitadas a: Geolocalización, disponibilización, separación y cronometración de tiempos, espacios y lugares en donde EL
USUARIO esté interesado de visitar y utilizar. <br />
Cualquier pregunta adicional que necesite consulta, VIZI ofrece 1 canal de atención, mediante el correo: <a href = "ayuda@vizispot.com"> ayuda@vizispot.com. </a>
Así también los usuario pueden visitar <a href = "https://www.vizispot.com/" target="_blank" >https://www.vizispot.com/ </a> <br /><br />
EL USUARIO declara que ha leído y aceptado estos términos y condiciones previamente a utilizar los servicios de la aplicación de
VIZI. <br />  <br />
<b style = "color:'black"> 1. Definiciones </b> <br />
Las presentes Condiciones Generales, debe entenderse por: <br />
1.1. "Usuario": cualquier Usuario que utilice los servicios de VIZI, en particular teniendo acceso a al menos una plaza de
aparcamiento puesta a disposición por VIZI, independientemente del medio por el que haya recibido este o estos accesos; <br />
1.2. “Socio”: cualquier Socio que reciba Usuarios por medio de los servicios de VIZI, en particular ofreciendo acceso a al menos
una plaza de aparcamiento puesta a disposición por VIZI, independientemente del medio por el que haya recibido este o estos
accesos; <br />
1.3. "Servicios": todos los servicios ofrecidos o prestados por VIZI en el marco de su actividad social, en particular la provisión de
plazas de aparcamiento al Usuario, a través de los Socio o medios proporcionados a tal efecto por VIZI, en particular la
Aplicación; <br />
1.4. "Aplicación": la aplicación móvil "VIZI" que permite, en particular, el acceso a las plazas de aparcamiento disponibles; <br />
1.5. "Sitio": el sitio web <a href = "https://www.vizispot.com/">www.vizispot.com</a> y sus subdominios.<br />  <br /> 
<b> 2. Finalidad </b> <br /> 
Las presentes Condiciones Generales tienen por objeto definir las condiciones y modalidades bajo las cuales VIZI pone los
Servicios a disposición del Usuario. <br />  <br />
<b> 3. Ámbito de aplicación </b> <br /> 
3.1. Las presentes Condiciones Generales son de aplicación a todos los Servicios puestos a disposición del Usuario y Socio por
VIZI. Estas condiciones se aplican a cada visita o uso del Sitio o de la Aplicación y a cada uso del aparcamiento. <br /> 
3.2. Las presentes Condiciones Generales prevalecen sobre cualquier otras condiciones generales, condiciones particulares o
cualquier otro documento que pueda haber emitido a VIZI. <br /> 
3.3. Las presentes Condiciones Generales regulan exclusivamente las relaciones entre VIZI y cualquier Usuario y Socio. <br /> 
3.4. El Usuario declara haber leído las presentes Condiciones y se compromete a cumplirlas. Las Condiciones están disponibles y
pueden ser consultadas en cualquier momento en la siguiente dirección: <a href = "https://www.vizispot.com/">www.vizispot.com</a> <br /> 
3.5. VIZI se reserva el derecho de modificar y actualizar en cualquier momento, a su entera discreción y sin previo aviso, estas
Condiciones Generales. Las Condiciones Generales modificadas son vinculantes para el Usuario cada vez que se utiliza el
Servicio. <br />
3.6. La nulidad, caducidad o inaplicabilidad de una de las disposiciones de las Condiciones Generales con las disposiciones
legales vigentes, no afectará a la validez de las demás disposiciones de las Condiciones Generales que conservarán su plena
vigencia legal. <br /> <br />
<b> 4. Entrada en vigor / Duración </b> <br />
4.1. Las presentes Condiciones Generales entrarán en vigor en la fecha de su puesta en línea y serán de aplicación en la fecha de
la primera utilización de los servicios por parte del Usuario y El socio. <br />
4.2. Las presentes Condiciones Generales son exigibles durante toda la duración del uso de la aplicación y de los Servicios, hasta
que las nuevas Condiciones Generales las sustituyan. <br />
4.3. El Usuario podrá en cualquier momento renunciar a utilizar la Aplicación y los Servicios ofrecidos en la misma pero queda
sujeto a las Condiciones Generales hasta que pida su baja oficial del servicio. <br /> <br />
<b> 5. Capacidad jurídica del Usuario </b> <br />
5.1. El Usuario declara expresamente que tiene el poder, la autoridad y la capacidad necesarios para la celebración y ejecución
de las obligaciones que le incumben para el uso del Sitio y los Servicios. <br />
5.2. Solo los Usuarios que tengan al menos 16 años de edad pueden utilizar el Servicio o la Aplicación.<br />
5.3. El Usuario declara que tiene la edad legal necesaria para celebrar un contrato vinculante. El Socio que crea una cuenta para
una persona jurídica declara estar autorizado para actuar de esta manera y para involucrar a esta persona con respecto a estas
condiciones de uso y para registrarlo con el fin de utilizar el Servicio y la Aplicación. <br />
<br />
<b> 6. Accesibilidad de la Aplicación y los Servicios </b> <br />
La Aplicación, el Sitio Web y los Servicios son normalmente accesibles las 24 horas del día, los 7 días de la semana.
Sin embargo, VIZI no se hace responsable de las interrupciones en la disponibilidad de la Aplicación, el Sitio Web y los Servicios
cuando estas interrupciones no dependan de su voluntad. <br />
VIZI se reserva el derecho, sin previo aviso ni compensación, de cerrar temporalmente la Aplicación, el Sitio Web o el acceso a
uno o más Servicios para actualizar, modificar o cambiar los métodos operativos, servidores y las horas de accesibilidad, sin que
esta lista sea exhaustiva.
VIZI se reserva el derecho, sin previo aviso ni compensación, de cerrar permanentemente la Aplicación, el Sitio Web o uno o más
Servicios, o de impedir que determinados Usuarios accedan al Sitio Web o a uno o más Servicios en aplicación de las presentes
Condiciones Generales.
VIZI se reserva el derecho de realizar a la Aplicación, al Sitio Web y a los Servicios todas las modificaciones y mejoras que
considere necesarias o útiles para el buen funcionamiento de su Sitio y de los Servicios asociados al mismo.<br /> <br />
<b> 7. Registro - Cuenta personal - Condiciones </b> <br />
7.1. El Usuario que desee utilizar los Servicios y/o la Aplicación deberá crear una cuenta personal a tal efecto a través del Sitio
Web y/o la Aplicación.. <br />
7.2. Para ello, el Usuario deberá cumplir las condiciones establecidas en el artículo 5 de las presentes Condiciones. <br />
7.3. En el momento del registro en la APP, se requerirán los apellidos, nombres, dirección de correo electrónico, detalles de la
bicicleta, la tarjeta de crédito del Usuario y más información. 
El Usuario se compromete a proporcionar información precisa, completa y actualizada.
También se le pedirá al Usuario que elija un nombre de usuario y contraseña estrictamente confidenciales.
A continuación, se le enviará un correo electrónico de confirmación. <br/>
7.4. El Usuario es responsable de preservar la confidencialidad de su nombre de usuario y/o contraseña. El Usuario no tendrá
derecho a distribuir esta información de acceso, ni a permitir que otros la utilicen para acceder a su perfil VIZI. El Usuario se
compromete a tomar medidas razonables para evitar que otros obtengan dicha información de acceso e informar de cualquier
acceso no autorizado directamente al Servicio de Atención al Cliente de VIZI por correo electrónico. <br/>
7.5 VIZI se reserva el derecho de verificar la información proporcionada en cualquier momento y de rechazar el servicio o el uso
de la aplicación sin tener que justificar su decisión.<br/>
7.6. Un usuario de VIZI puede registrar hasta 2 vehículos en su perfil. Posteriormente, por cada placa adicional, el usuario estará
sujeto a una tarifa administrativa de 10 soles. <br/>
7.7. Un usuario de VIZI puede registrar hasta 2 números de teléfono en su perfil. Posteriormente, por cada número adicional, el
usuario estará sujeto a una tasa administrativa de 10 soles. <br/> <br/>
<b> 8. Uso de la aplicación o servicio  </b> <br/>
8.1. El Usuario solo podrá acceder a los Servicios utilizando los medios de acceso facilitados a tal efecto por VIZI. Corresponde al
Usuario y El Socio comprobar que descarga la aplicación compatible con su teléfono. VIZI declina toda responsabilidad si el
teléfono móvil del usuario no es compatible o si el Usuario descarga una versión incompatible de la aplicación. VIZI se reserva el
derecho de rescindir los Servicios y el uso de la aplicación si el Usuario utiliza el servicio o la aplicación con un Móvil
incompatible o prohibido. <br />
8.2. Al utilizar la aplicación o el servicio, el Usuario o el Socio aceptan:<br/>
1. utilizar los Servicios o descargar la aplicación solo para su propio uso personal y no revenderlos a un tercero;<br/>
2. no autorizar a terceros a utilizar su cuenta;<br/>
3. no ceder ni transferir su cuenta a ninguna otra persona física o jurídica; <br/>
4.no utilizar el servicio o la aplicación con fines ilegales, en particular para enviar o guardar contenido ilegal o con fines
fraudulentos; <br/>
5. no utilizar el servicio o la aplicación con el fin de dañar, avergonzar o molestar a terceros; <br/>
6. no comprometer el buen funcionamiento de la red; <br/>
7. no interrumpir el servicio o la aplicación de ninguna manera; <br/>
8. no copiar o distribuir la aplicación o cualquier otro contenido de VIZI sin su permiso por escrito;  <br/>
9. enviarnos una prueba de identidad a petición; <br/>
10. utilizar un punto de acceso o una cuenta de datos 3G (AP) solo si está autorizado para hacerlo; <br/>
11. no utilizar el servicio o la aplicación con un teléfono móvil incompatible o prohibido; <br/>
12. para cumplir con todas las leyes o regulaciones aplicables del país de origen, el país, el estado y / o la ciudad donde se
encuentra el Usuario al usar la aplicación o el servicio.<br/>
8.3. El Usuario se compromete a respetar, y a garantizar el respeto, las condiciones generales, así como el reglamento interno (a
continuación), las normas de seguridad y la señalización, del aparcamiento (s) puesto a su disposición. <br/>
8.4. El uso fraudulento del Sitio o que contravenga las condiciones generales puede dar lugar a la denegación por parte de VIZI,
en cualquier momento, del acceso a los Servicios ofrecidos por el Sitio, aunque VIZI haya confirmado el acceso a los Servicios,
sin perjuicio de cualquier acusación, ofrecido a VIZI ante los tribunales competentes en virtud de las presentes condiciones
generales. Por otro lado, VIZI se reserva el derecho de dar por terminado inmediatamente el servicio y el uso de la aplicación si
el Usuario no cumple con una de las reglas establecidas anteriormente. <br/> <br/>
<b> 9. Acceso a las plazas de aparcamiento </b> <br/>
9.1. La accesibilidad al aparcamiento depende del aparcamiento y de los medios de acceso elegidos por el Usuario o el Socio: <br/>
A. Ya sea mediante el uso de la aplicación móvil VIZI <br/>
B. Ya sea mediante la obtención de un distintivo, una tarjeta de acceso, llaves u otros medios de acceso proporcionados por VIZI
(este medio de acceso está sujeto a un depósito y a disponibilidad de stock)
En el caso de utilizar la opción A. anterior, el usuario deberá seguir el procedimiento de salida comunicado por VIZI aunque la
persiana/barrera del aparcamiento esté abierta, con el fin de notificar a VIZI la finalización del aparcamiento. En caso contrario,
el uso del aparcamiento se seguirá facturando al usuario. <br/>
9.2. En el caso de que el usuario estacione en un aparcamiento sin una compra válida, su vehículo podrá ser recogido como se
indica a continuación en el apartado "Detención, inmovilización, circulación y retirada de vehículos". <br/>
9.3. Los aparcamientos sólo son accesibles para vehículos con una longitud máxima de 3,80 m. La altura de los vehículos no
podrá superar la indicada a la entrada del aparcamiento o impuesta por la situación real. Está prohibido entrar en el
aparcamiento con remolques de cualquier tipo, incluidas las caravanas. Las bicicletas también pueden tener acceso a las plazas
de aparcamiento previstas para este fin. <br/>
9.4. Si se aplican condiciones específicas para los aparcamientos utilizados, además de estas condiciones generales, se indicarán
en el comprobante de compra proporcionado al final del proceso de reserva. <br/>
9.5. VIZI tiene derecho a denegar el acceso a un usuario que no cumpla con las presentes condiciones generales, las condiciones
propias de los aparcamientos o la normativa interna del aparcamiento puesto a su disposición. <br/> <br/>
<b> 10. Uso del aparcamiento  </b>  <br/>
10.1. El usuario sólo podrá acceder, aparcar y salir del aparcamiento durante el horario correspondiente a su compra. Estos se
pueden ver a través de su cuenta de cliente en línea. Los horarios de apertura aplicables podrán ser modificados por VIZI, que
notificará a los usuarios a través del Sitio, la Aplicación o por correo electrónico. <br/>
10.2. El Usuario solo podrá estacionar su vehículo en espacios reservados para los Usuarios de VIZI y correspondientes a su tipo
de vehículo. VIZI le comunicará estas ubicaciones al hacer la reserva. En ningún caso el usuario podrá aparcar en un lugar
inconveniente o no autorizado. <br/>
10.3. En caso de incumplimiento de las presentes condiciones generales, VIZI podrá retirar el vehículo a expensas, riesgo y
riesgo del Usuario de acuerdo con el contenido a continuación en el apartado "Detención, inmovilización, circulación y retirada
de vehículos". VIZI cargará automáticamente y sin formalidad en la cuenta del Usuario los gastos de evacuación del vehículo sin
perjuicio de la exclusión de los servicios de VIZI tal y como se describe en el apartado "Detención, inmovilización, movimiento y
recogida de vehículos". <br/>
10.4. El usuario y todas las personas que lo acompañan están obligados a respetar la naturaleza pacífica del vecindario cuando
viajen dentro y alrededor del estacionamiento. Queda prohibido el uso de radios y otras fuentes de ruido en el aparcamiento.<br/>
10.5. El usuario se compromete a dejar el aparcamiento en un estado de limpieza impecable. Queda terminantemente
prohibido lavar los vehículos en el aparcamiento, realizar allí cualquier trabajo o depositar o almacenar allí cualquier cosa que
no sea un vehículo válidamente matriculado y asegurado o una bicicleta identificada y acoplada, y utilizada regularmente. El
espacio de estacionamiento no es un espacio de almacenamiento o almacenamiento a largo plazo. Los vehículos no utilizados
deberán ser evacuados previa advertencia, como se indica a continuación en la sección "Detención, inmovilización, circulación y
retirada de vehículos". <br/>
10.6. Los vehículos estacionados en el aparcamiento deberán cumplir las mismas condiciones que las impuestas a los vehículos
estacionados en la vía pública. El Usuario declara, en particular, que el vehículo (s) (excepto bicicletas o scooters) que estaciona
utilizando los Servicios de VIZI es o es objeto de un seguro de Responsabilidad Civil válido y en orden de pago. El Código de
Circulación y cualquier otra normativa relativa al tráfico en la vía pública se aplican en el aparcamiento, salvo que se especifique
lo contrario. En todos los casos, la velocidad máxima autorizada de los vehículos está limitada a 10 km/hora. <br/>
10.7. Las bicicletas deben estar válidamente unidas a los postes dispuestos a tal efecto (máximo 2 bicicletas por poste, una a
cada lado; un abono de bicicleta autoriza el estacionamiento de una sola bicicleta). Los vehículos quedan bajo la plena
responsabilidad del Usuario. Cualquier objeto, cualquiera que sea, dejado en el vehículo, o accesorio adherido al vehículo, es
responsabilidad exclusiva del Usuario. <br/>
10.8. La provisión de estacionamiento no puede estar sujeta a ninguna transferencia o subarrendamiento. <br/>
10.9. Si el tiempo de acceso al estacionamiento se excede en más de 30 minutos, se enviará una advertencia por correo
electrónico al usuario. En caso de reincidencia, se facturará una multa administrativa de 20 soles a la cuenta del usuario. <br/>
10.10. Si se observa que un usuario estaciona más vehículos en el aparcamiento de los que tiene suscripciones, recibirá un aviso
por correo electrónico. <br/> <br/>
En caso de reincidencia, se facturará a la cuenta del usuario una multa administrativa, equivalente a 1 mes de suscripción
premium por vehículo adicional. <br/> <br/>
<b> 11. Detención, inmovilización, circulación y retirada de vehículos </b> <br/>
11.1. En caso de accidente o inmovilización involuntaria de un vehículo, el usuario tomará inmediatamente las medidas
necesarias para reparar el vehículo de manera que no interfiera de ninguna manera con el tráfico normal en el establecimiento.
En cualquier caso, el Usuario recogerá el vehículo antes de la finalización de la franja horaria durante la cual esté autorizado
para acceder al aparcamiento. En todos los casos, el usuario se pondrá en contacto con el servicio de atención al cliente de VIZI. <br/>
11.2. El usuario autoriza formalmente a VIZI y/o al titular del aparcamiento a desplazar su vehículo o a que lo traslade, a costa y
riesgo del usuario, en el caso de que: <br/>
❏ no está estacionado en los lugares provistos; <br/>
❏ se deja en el aparcamiento fuera del horario de accesibilidad sin el consentimiento previo por escrito de VIZI; <br/>
❏ constituye un peligro para las personas y/o bienes ajenos. <br/>
❏ interfiere con la circulación normal; <br/>
❏ no se usa (y se ha emitido una advertencia de antemano); <br/>
❏ los requisitos operativos o de seguridad así lo requieren. <br/>
En estos casos, los gastos de viaje se fijan en una tarifa plana de 300 soles más impuestos.<br/>
11.3. El usuario se compromete a retirar su vehículo del sitio de la compañía de recuperación a través del cual se ha movido
dentro de las 24 horas posteriores a la recuperación. El Usuario acepta que la empresa de recuperación estará autorizada a
cobrarle tarifas de estacionamiento al usuario después de un período de 24 horas. <br/>
11.4. Las bicicletas y bicicletas de carga no recogidas o reclamadas después de un período de 30 días a partir de la fecha de
retiro del estacionamiento se considerarán pérdidas. VIZI se reserva el derecho de no poder devolver la bicicleta a su propietario
después de este período. <br/> <br />
<b> 12. Precios </b> <br/>
12.1. El registro a través de la aplicación con VIZI es gratuito. El precio por cada plaza de aparcamiento se muestra en el sitio o
en la Aplicación (en adelante, el "Precio") en soles con IGV incluido y varía según el estacionamiento y la duración del
estacionamiento. <br/>
12.2. En caso de uso a corto plazo y sin reserva, las tarifas se indican por minuto. El precio cobrado depende del tiempo que el
vehículo esté en el aparcamiento calculado entre el momento de la apertura y el momento del cierre. En caso de uso con
reserva, los precios se indican por día o por mes. Si el Usuario estaciona su vehículo más allá de la duración de la reserva, la
tarifa por minuto se aplicará desde el final de la reserva. <br/>
12.3. VIZI se reserva el derecho de modificar sus precios en cualquier momento. Sin embargo, los cambios no pueden tener
efecto retroactivo. En el caso de un cambio en los precios por parte de VIZI, el nuevo precio puede entrar en vigor tan pronto
como se emita la próxima factura. <br/>
12.4. En caso de incumplimiento del horario de apertura del aparcamiento, VIZI se reserva el derecho de aplicar un sobreprecio
por minuto por cada minuto de rebasamiento. <br/>
12.5. A menos que se especifique lo contrario al hacer la reserva, cualquier cancelación o modificación de una reserva es
gratuita hasta 24 horas antes del inicio de la reserva. Una vez que el período de uso ha comenzado, se debe en su totalidad. <br/> <br/>
<b> 13. Tarifas </b> <br/>
VIZI cobra tarifas variables basadas en precios para poder disponibilizar cocheras a los Usuarios, explicadas en el apartado 12; y
tarifas fijas dependiendo del tipo de cuenta que el Usuario desea free o premium. La principal característica premium es que
permite reservar un estacionamiento por periodos de corto plazo. La versión gratuita muestra los estacionamientos pero no los
reservará para el uso del Usuario. <br/> <br/>
<b> 14. Pago  </b> <br/> 
14.1. Existen solo un medio de pago para utilizar los Servicios de Pago de VIZI:  <br />
- Pago con tarjeta de crédito y/o débito <br/>
14.2. La provisión de espacios de estacionamiento al Usuario por parte de VIZI es de pago, de acuerdo con el punto "12. Precios"
de las presentes condiciones generales. <br/>
14.3. El usuario reconoce que VIZI puede debitar la cuenta de la tarjeta que comunicó al registrarse en los Servicios, por los
Servicios que solicitó (incluidos impuestos y posibles multas por mora) sin autorización adicional. Los pagos realizados no son
reembolsables. <br/>
14.4. VIZI utiliza un sistema de pago de terceros ("el sistema de pago") para asociar la cuenta de tarjeta del Usuario con la
aplicación y los Servicios. La tramitación de cualesquiera pagos o créditos derivados del uso de la aplicación y del servicio por
parte del Usuario se regirá por las condiciones y la política de privacidad del sistema de pagos y por las condiciones del emisor
de la tarjeta, además de las presentes condiciones de uso y de conformidad con las disposiciones legales vigentes. <br/>
14.5. VIZI declina toda responsabilidad en caso de error debido al sistema de pago. El Usuario reconoce y acepta que VIZI
obtendrá ciertos detalles de las transacciones, que utilizará de acuerdo con estas condiciones generales y el marco legal vigente. <br/>
14.6. VIZI garantiza pagos seguros y respeta los estándares SSL para proteger los datos de manera efectiva. <br/>
14.7. En caso de denegación de autorización de pago con tarjeta bancaria, VIZI se reserva el derecho de actuar para recuperar
las deudas generadasn mediante recordatorios de pago, bloqueo temporal del acceso del usuario o la transmisión del
expediente a una empresa de cobro externa. <br/>
14.8. En caso de impago a tiempo, intereses al tipo de interés legal aplicable en caso de retraso en las operaciones comerciales
vigentes en el momento del impago, así como una cantidad de 50 soles para los gastos de recuperación, independientemente
de la suma principal. <br/>
14.9. El usuario declara haber entendido que cualquier orden de cobro emitida debe ser pagada en su totalidad y que la
rescisión del contrato no puede en ningún caso tener un efecto retroactivo en las facturas emitidas. <br/> <br/> 
<b> 15. Compensación  </b> <br/>
Al aceptar estas condiciones generales y al utilizar la aplicación o el servicio, el Usuario se compromete a defender, indemnizar y
garantizar a VIZI, sus filiales, sus licenciantes y cada uno de sus funcionarios, directores, otros usuarios, empleados, agentes. y
agentes contra reclamaciones, costos, daños, pérdidas, compromisos y gastos (incluidos los costos legales, honorarios y
honorarios de abogados) que surjan de o en relación con: <br/>
1. cualquier violación de las disposiciones de estas condiciones generales o de cualquier legislación o regulación aplicable, ya
sea que se mencione o no en este documento, de la cual el Usuario sea culpable; <br/>
2. cualquier violación de los derechos de terceros; <br/>
3. cualquier uso fraudulento de la aplicación o servicio, del que el Usuario sea culpable. <br/> <br/>
<b> 16. Responsabilidad  </b> <br/>
16.1. La información, recomendaciones y/o servicios prestados al Usuario directa o indirectamente por el Sitio, los Servicios y la
Aplicación, constituyen únicamente información general y no opiniones. VIZI se esforzará por mantener el Sitio, la Aplicación y
su contenido en un estado correcto y actualizado, pero no garantiza que el sitio web y / o la aplicación (y su contenido) estén
libres de errores, defectos, malware o virus, o que el Sitio y / o la Aplicación sean correctos, actualizados y precisos. VIZI no será
responsable de ningún daño resultante del uso (o la incapacidad de usar) el Sitio o la Aplicación, incluidos los daños causados
por malware, <br/>
16.2. VIZI no será responsable de los daños resultantes del uso (o la imposibilidad de usar) los medios electrónicos de
comunicación con el Sitio o la Aplicación, en particular los daños resultantes de una falla o retraso en la transmisión de las
comunicaciones electrónicas, la interceptación o manipulación de las comunicaciones electrónicas por parte de terceros o por
programas informáticos utilizados para las comunicaciones electrónicas y la transmisión de virus. <br/>
16.3. VIZI y el propietario (s) de los aparcamientos puestos a disposición de los Usuarios declinan toda responsabilidad, por
cualquier motivo y por cualquier motivo, por cualquier daño generalmente de cualquier tipo, tangible o intangible, a personas o
bienes tales como, en particular: accidentes, robos o daños, incluso parciales, que pudieran producirse con ocasión o debido al
uso del aparcamiento. El usuario se compromete a informar inmediatamente de cualquier daño al servicio de atención al cliente
de VIZI. <br/>
16.4. Además, al no tener la capacidad de depositario, VIZI no asume de ninguna manera una obligación de custodia o vigilancia
y no incurre en responsabilidad por las acciones de terceros. <br/>
16.5. VIZI únicamente contrata obligaciones de medios, para todos los Servicios ofrecidos o prestados. VIZI no se hace
responsable de ningún inconveniente o daño inherente al uso de la red de Internet, en particular una interrupción en el servicio,
una intrusión externa o la presencia de virus informáticos, o cualquier hecho que pueda calificarse como fuerza mayor. En
cualquier caso, las responsabilidades de VIZI en los términos de las presentes condiciones generales no podrán exceder de un
importe igual a las cantidades abonadas o pagaderas durante la operación que dé lugar a dicha responsabilidad, cualquiera que
sea la causa o forma de la acción de que se trate. <br/>
16.6. El Usuario es el único responsable de cualquier daño a su ordenador / celular o cualquier pérdida de datos resultante de la
descarga, consulta y uso del Sitio Web y/o la Aplicación. <br/>
16.7. El Usuario reconoce que tiene la opción, sin obligación alguna y bajo su exclusiva responsabilidad, de utilizar las redes
sociales, como Facebook, Twitter, Google, etc. VIZI no se hace responsable en modo alguno del uso o tratamiento de esta
información, datos y contenidos en el marco de las redes sociales. <br/>
16.8. El Usuario deberá asegurarse, antes de cualquier uso de esta opción, de conocer las condiciones de uso de estas redes
sociales, de poner en marcha las medidas de protección y parámetros necesarios para un correcto uso, de respetar las
condiciones de uso de estas redes sociales. <br/>
16.9. El Usuario se compromete a utilizar el Sitio Web, la Aplicación y los Servicios únicamente con fines privados y con una
finalidad acorde con el orden público, la buena moral y los derechos de terceros. <br/>
16.10. El Usuario se compromete a no realizar ninguna acción que pueda, directa o indirectamente, socavar la integridad del
Sitio Web, la Aplicación y/o los Servicios puestos a su disposición por VIZI. <br/>
16.11 En caso de pérdida, robo o degradación grave del distintivo de acceso al aparcamiento, se cobrará al usuario una tarifa fija
de 500 soles con impuestos incluidos. <br/> <br/>
<b> 17. Licencia por parte del usuario </b> <br />
17.1. El Usuario reconoce expresamente, de forma exclusiva y gratuita, a VIZI, el derecho a explotar cualquier contenido
publicado por el Usuario a través de la Aplicación y/o el Sitio Web en Internet, en redes sociales o por cualquier medio conocido
o desconocido hasta la fecha, con fines comerciales o publicitarios, todo ello sin restricciones territoriales y mientras VIZI opere
la Aplicación y/o el Sitio Web sujeto a las presentes condiciones generales. <br />
17.2. Sin embargo, VIZI no tiene ninguna obligación de usar y / o explotar el (los) contenido (s) publicado (s) por el Usuario a
través de la Aplicación y / o el Sitio Web. <br />
17.3. El Usuario reconoce que VIZI actúa como un simple intermediario para la distribución del contenido del usuario y no es
responsable ante nadie por el contenido o la exactitud del contenido del usuario. VIZI no controlará permanentemente el
contenido de usuario publicado y no desempeñará un papel de moderador entre usuarios. Además, VIZI no estará obligado a
hacerlo. Sin limitar lo anterior, el Usuario reconoce y acepta que los comentarios, opiniones, sugerencias y otra información
expresada o incluida en el contenido del usuario no reflejan necesariamente los puntos de vista de VIZI. <br />
17.4. El Usuario declara y garantiza que el contenido del usuario que publica o transmite proviene de él, no es plagio de
creaciones de terceros, no constituye una violación de los derechos de propiedad intelectual de terceros, del derecho a la vida,
la privacidad o los derechos de la personalidad, y que no contienen comentarios difamatorios o despectivos. <br />
17.5. El Usuario acepta y se compromete a garantizar permanentemente a VIZI, sus subsidiarias y sus licenciantes contra todos
los costos, gastos, daños, pérdidas y compromisos incurridos o sufridos por VIZI o sus compañías relacionadas debido al
contenido del usuario publicado o transmitido por él, o que resulte de su uso del Sitio Web, los Servicios o la Aplicación. <br />
17.6. VIZI se reserva el derecho de bloquear o eliminar, total o parcialmente, el contenido del usuario publicado o transmitido, si
VIZI considera que no cumple con estos Términos de uso o que es inaceptable por cualquier otro motivo. <br />
17.7. El Usuario se compromete a notificar a VIZI por escrito lo antes posible si algún contenido del usuario viola estos Términos
de uso. El Usuario se compromete a proporcionar a VIZI información suficiente para que pueda verificar si este contenido de
usuario viola estos Términos de uso. Sin embargo, VIZI no garantiza ni declara que bloqueará o eliminará (en su totalidad o en
parte) el contenido del usuario en cuestión. <br /> <br />
<b> 18. Concesión de licencia para la solicitud </b>  <br />
18.1. Si el Usuario cumple con estos Términos de Uso, VIZI le otorga una licencia limitada, no exclusiva e intransferible que le
permite descargar e instalar una copia de la Aplicación en un teléfono móvil que posea o controle, y usar esta copia de la
Aplicación solo para uso personal. <br />
18.2. El Usuario no podrá: <br />
1. licenciar o sublicenciar, vender, revender, transferir, asignar, distribuir o explotar comercialmente o poner a disposición de
terceros el servicio o la aplicación de cualquier manera; <br />
2. modificar el servicio o la aplicación o crear derivados del mismo; <br />
3. crear enlaces electrónicos a los Servicios o colocar marcos o espejos de cualquier aplicación en cualquier otro servidor o
dispositivo inalámbrico o basado en Internet; <br />
4. realizar ingeniería inversa o acceder a la aplicación para a. diseñar o construir un producto o servicio competitivo; <br />
b. diseñar o construir un producto utilizando ideas, características, funciones o imágenes similares del servicio o aplicación;
vs. copiar ideas, características, funciones o imágenes del servicio o aplicación; <br />
5.Lanzar un programa o script automatizado, incluyendo robots de software, rastreadores, robots web, navegadores web,
indexadores web, zombis, virus o gusanos, o cualquier programa capaz de enviar múltiples solicitudes por segundo a un servidor
o para complicar o dificultar inadvertidamente el funcionamiento y / o rendimiento del servicio o aplicación. <br />
18.3. El Usuario no podrá: <br />
1. enviar o guardar mensajes no solicitados o repetitivos, en violación de la ley aplicable; <br />
2. enviar o guardar contenido infractor, obsceno, amenazante, difamatorio o ilegal o ilícito, incluido el contenido dañino enviado
a niños o que viole el derecho a la privacidad de otros; <br />
3. enviar o guardar contenido que contenga virus, gusanos, troyanos u otro código informático, archivos, scripts, agentes o
programas dañinos; <br />
4.dificultar o interrumpir la integridad o el correcto funcionamiento del sitio web, aplicación o servicio o de los datos que
contienen; <br />
5. intentar acceder de forma fraudulenta al sitio web, aplicación o servicio o a los sistemas o redes que los soportan. <br />
18.4. VIZI tendrá el derecho de investigar las violaciones anteriores, en la máxima medida permitida por la ley, y de iniciar
procedimientos legales. VIZI puede apelar a las autoridades policiales y cooperar con ellas para procesar a los usuarios que
violen estos Términos de uso. <br />
18.5. El Usuario reconoce que VIZI no está obligado a controlar su acceso al sitio web, servicios, aplicación o contenido colectivo
ni al uso que se haga del mismo, ni a verificar o editar contenidos colectivos. El Usuario reconoce, no obstante, que VIZI tiene
derecho a controlar su acceso en el marco del funcionamiento del sitio web, la aplicación y el servicio, para garantizar el
cumplimiento de estos Términos de uso o para cumplir con la legislación. aplicable o una orden judicial o solicitud de un
tribunal, órgano administrativo u otro organismo oficial. VIZI se reserva el derecho a cancelar o desactivar, en cualquier
momento y sin previo aviso, el acceso a cualquier contenido colectivo si considera que infringe las presentes condiciones. <br /><br />
<b> 19. Derechos de propiedad intelectual  </b> <br />
19.1. La Aplicación y el Sitio Web contienen textos, imágenes (fijas o animadas) y sonidos, que son propiedad exclusiva de VIZI.
Estos elementos están protegidos por derechos de autor, así como por la propiedad intelectual y para todo el mundo (en
adelante, "Elementos de Propiedad Intelectual"). <br />
19.2. El nombre, el logotipo y los nombres de productos de VIZI asociados con la aplicación y el servicio son marcas comerciales
de VIZI, sus empresas relacionadas o terceros. VIZI no otorga ningún derecho o licencia para usar estas marcas. <br />
19.3. Los Elementos de Propiedad Intelectual se ponen a disposición del Usuario, de forma gratuita, para el uso exclusivo de los
servicios y en el marco del uso normal de sus funcionalidades. Esta autorización para utilizar los Elementos de Propiedad
Intelectual se realiza de forma personal, no exclusiva e intransferible. VIZI puede revocar esta autorización para utilizar los
Elementos de Propiedad Intelectual en cualquier momento. <br />
19.4. Cualquier copia o falsificación del concepto o formato del Sitio Web y / o la Aplicación por cualquier medio y en cualquier
medio está estrictamente prohibida. <br />
19.5. Cualquier uso en cualquier forma debe estar sujeto a la autorización previa por escrito de VIZI. <br />
19.6. En caso de uso no conforme o abusivo de los Elementos de Propiedad Intelectual, VIZI se reserva el derecho de poner fin a
la infracción de sus derechos de propiedad intelectual por todos los medios legales. <br />
19.7. Cualquier infracción puede dar lugar a procedimientos civiles y / o penales. <br /><br />
<b> 20. Interacciones con terceros </b>  <br />
20.1. Al utilizar el sitio web, la aplicación y los servicios, se pueden proporcionar enlaces a sitios web propiedad y controlados
por terceros con el fin de poner al Usuario en contacto con estos terceros, para comprar sus bienes. o sus servicios, para
participar en sus promociones. Estos enlaces sacan al Usuario del sitio web, la aplicación y los servicios y no están controlados
por VIZI. VIZI no es responsable y no se hace responsable del contenido y las actividades de estos sitios web. El Usuario accede,
visita y utiliza estos sitios web bajo su propio riesgo. <br />
20.2. Estos otros sitios web pueden enviar sus propias cookies a los usuarios, recopilar datos o solicitar información personal.
VIZI aconseja que comprueben las condiciones de uso y la política de privacidad de estos sitios web antes de utilizarlos. <br /> <br />
<b> 21. Duración y resolución del contrato </b>  <br />
21.1. Tras el registro del Usuario o el Socio en VIZI y la aceptación de las presentes Condiciones Generales, se forma un contrato
indefinido entre VIZI y el Usuario a través del cual este último puede utilizar los servicios de VIZI. <br />
21.2. El Usuario puede dejar de utilizar los servicios de VIZI en cualquier momento eliminando permanentemente la aplicación
instalada en su teléfono inteligente y cerrando su cuenta de usuario siguiendo las instrucciones en el sitio web de VIZI. Todos los
consumos aún no facturados siguen siendo exigibles. El usuario podrá cancelar su reserva de forma gratuita a más tardar 24
horas antes del inicio del período de reserva para la ubicación. <br />
21.3. El usuario suscriptor se compromete durante un período mínimo de 3 meses a partir del primer día del mes siguiente al
inicio de su suscripción. La naturaleza del contrato que lo vincula con VIZI es un contrato mensual con renovación tácita (a
menos que se especifique lo contrario). Pasados los 3 meses, el usuario suscriptor dejará de usar los servicios cerrando la
suscripción en su cuenta. Las cancelaciones son efectivas el último día del mes. <br />
21.4. VIZI puede dejar de prestar los Servicios en cualquier momento, con efecto inmediato (desactivando las posibilidades de
uso de la aplicación y los Servicios) y sin reembolso. <br />
1. si el Usuario infringe las presentes condiciones generales; <br />
2. si VIZI considera que el Usuario está abusando de la aplicación o de los Servicios. VIZI no está obligado a advertir al Usuario
con antelación de la terminación de los Servicios que utiliza. <br />
21.5. VIZI se reserva el derecho de dar por terminado todo o parte del derecho de acceso a las cuentas personales de los
Usuarios registrados o incluso de eliminar su cuenta, debido a la falta de uso y activación de la cuenta por un período de un año
y / o en el caso de que el Usuario registrado haya violado estas Condiciones Generales. <br />
21.6. VIZI puede proceder con cualquier restricción y / o eliminación sin previo aviso y tampoco puede ser considerado
responsable de la pérdida de datos resultante de la eliminación de la cuenta personal del Usuario. <br />
21.7. VIZI puede dejar de prestar los Servicios en cualquier momento, con efecto inmediato (desactivando las posibilidades de
uso de la aplicación y los Servicios) a su discreción e incluso si el período actual ya ha sido facturado. En la medida en que el
usuario no sea responsable de la interrupción de los servicios, los importes de sobrepago podrán ser reembolsados y
acreditados según decida VIZI. <br /> <br/>
<b> 22. Protección de la intimidad en lo que respecta al tratamiento de datos personales </b> <br/>

22.1. El Usuario garantiza proporcionar datos personales correctos y completos al realizar un pedido o registrarse en el App VIZI.  <br/>
22.2. VIZI se compromete a no revelar datos personales a terceros. Estos son confidenciales. Solo serán utilizados por sus
departamentos internos para el procesamiento de reservas, con fines de comunicación y personalización. <br/>
22.3. En caso de cesión o uso por parte de terceros de datos personales, VIZI se compromete a informar previamente al Usuario
con el fin de permitirle ejercer su derecho de oposición. VIZI también puede proporcionar estadísticas consolidadas
relacionadas con sus usuarios, reservas, la estructura de los intercambios y la información en el Sitio a terceros de confianza,
pero estas estadísticas no contendrán ningún dato personal. Sin embargo, este artículo no puede impedir la venta o
transferencia de actividades a un tercero. <br/>
22.4. De acuerdo con el Reglamento General de Protección de Datos (GDPR), el Usuario tiene derecho de acceso, control,
rectificación y, posiblemente, eliminación completa de sus datos personales. Cualquier solicitud en este sentido puede
gestionarse a través de la cuenta de cliente del usuario, o a través del Servicio de Atención al Cliente de VIZI por correo
electrónico con prueba de identidad (foto del documento de identidad). De acuerdo con la ley, VIZI se reserva el derecho de
conservar los datos del usuario necesarios para sus operaciones y para el control de los mismos por parte de instituciones
autorizadas durante un período de 2 (dos) años. <br/>
22.5. La solicitud de eliminación de datos personales no se puede aplicar mientras un contrato esté en curso o por llegar a una
reserva. Esta solicitud no se asociará automáticamente a una solicitud de cierre del contrato, éstas deberán cerrarse
previamente de acuerdo con las condiciones descritas en el punto 21. <br/>
22.6. El Sitio utiliza el uso de cookies. La cookie es un archivo informático, almacenado en el disco duro del ordenador personal
del usuario, cuya finalidad es informar de una visita previa del Usuario o del usuario al Sitio. Las cookies son, entre otras cosas,
utilizadas por VIZI con el fin de personalizar el servicio ofrecido a los usuarios. El Usuario conserva la posibilidad de rechazar las
cookies mediante la configuración de su navegador de Internet. Luego pierde la posibilidad de personalizar el servicio que le
brinda VIZI. <br/>
22.7. Algunas páginas web en el sitio a veces pueden contener imágenes electrónicas, que se utilizan para contar el número de
visitantes a la página. Esta información puede ser utilizada por algunos de los socios de VIZI, en particular para medir y mejorar
la efectividad de ciertas acciones. La información obtenida simplemente permite recopilar estadísticas sobre el número de
visitas a ciertas páginas del Sitio. <br/> <br/>
<b> 23. No renuncia </b> <br/>
23.1. El hecho que VIZI no se aproveche de un incumplimiento del Usuario o del Socio en ninguna de sus obligaciones
contractuales o legales no puede interpretarse como una renuncia a aprovecharse de este incumplimiento o este
incumplimiento. <br/>
23.2. El hecho de que VIZI no haga uso de una cláusula de estas Condiciones no implica una renuncia al beneficio de dicha
cláusula. <br/> <br />
<b> 24. Ley aplicable y resolución de disputas  </b> <br/> 
24.1. Sólo la ley Peruana es aplicable a las relaciones contractuales. En caso de litigio, sólo serán competentes los tribunales
peruanos del distrito judicial de Lima. Para cualquier disputa sobre la jurisdicción del Juez de Paz en el distrito judicial de Lima. <br/> <br />
<b> 25. Normativa interna de los aparcamientos VIZI  </b> <br/> 
25.1. Solo los miembros de VIZI están autorizados a permanecer en las plazas de aparcamiento, de conformidad con las
condiciones definidas a continuación. <br/>
25.2. El aparcamiento es accesible según los horarios indicados en la aplicación de VIZI. VIZI se reserva el derecho de retirar la
disponibilidad de aparcamiento en determinadas fechas. <br/>
25.3. El vehículo debe estar estacionado en un espacio autorizado destinado a su tipo de vehículo. Bajo ninguna circunstancia el
usuario podrá estacionar en un lugar inconveniente o no autorizado. <br/>
25.4. La velocidad máxima de los vehículos está limitada a 10 km / hora y las normas del código de circulación son aplicables en
el aparcamiento. <br/>
25.5. El usuario está obligado a respetar el carácter pacífico del barrio y el buen estado de mantenimiento de las instalaciones.
Queda prohibido el uso de radios y otras fuentes de ruido en el aparcamiento. Los usuarios tienen estrictamente prohibido lavar
su coche en el aparcamiento y realizar cualquier trabajo allí. <br/>
25.6. Mientras los vehículos están estacionados en el aparcamiento, ninguna persona o animal podrá permanecer allí. <br/>
25.7. Los vehículos son responsabilidad exclusiva del usuario. Cualquier objeto, cualquiera que sea, dejado en el vehículo, es
responsabilidad exclusiva del usuario. Esto también aplica a todos los accesorios del vehículo. <br/>
25.8. El propietario del parking o Socio se limita a poner a disposición de los usuarios de VIZI plazas de aparcamiento y, por
tanto, no asume responsabilidad alguna de ningún tipo. Además, VIZI y el propietario declinan toda responsabilidad, por
cualquier motivo y por cualquier daño generalmente de cualquier tipo, tangible o intangible, en particular: accidentes, robos o
daños, incluso parciales, que pudieran ocurrir ocasionalmente o debido al uso del aparcamiento, incluso en particular durante la
llegada o salida de un usuario o su estancia en el aparcamiento. <br/>
25.9. VIZI y el propietario están autorizados a retirar del aparcamiento los vehículos de usuarios sin título o que no respeten las
franjas horarias definidas en los artículos 2 y 3 o que estén estacionados en lugares inconvenientes. VIZI cargará
automáticamente y sin previo aviso en la cuenta del usuario los gastos de evacuación del vehículo. <br/>
25.10. Para cualquier problema técnico con las barreras o en caso de emergencia, el usuario se pondrá en contacto con el
servicio de asistencia de VIZI en las señales de VIZI dentro del aparcamiento. <br/>
25.11. Cualquier violación de dicho reglamento interno o de las condiciones generales de VIZI por parte del usuario dará lugar a
la exclusión total y sin previo aviso del miembro del servicio VIZI. El usuario no podrá reclamar el reembolso de sus consumos o
suscripciones actuales. <br/>
25.13. Normas específicas para el estacionamiento de bicicletas <br/>
25.13. 1. Solo los usuarios con una suscripción de bicicleta VIZI en regla pueden estacionar su bicicleta en los postes provistos
para este fin. <br/>
25.13. 2. Una suscripción para un espacio para bicicletas da derecho al suscriptor a estacionar una sola bicicleta (bicicleta en
formato estándar), una suscripción para un espacio para bicicletas de carga solo una bicicleta de carga. <br/>
25.13. 3. La bicicleta para la que se realiza la suscripción en un aparcamiento VIZI debe llevar un número único que se puede
obtener en la aplicación de VIZI. Este número debe pegarse en el cuadro de la bicicleta en el lugar prescrito (utilizando la
pegatina proporcionada para este propósito). <br/>
25.13.4. El estacionamiento de bicicletas en los postes es el siguiente: <br/>
1. Un arco permite el estacionamiento de 2 bicicletas, una a cada lado <br/>
2. La bicicleta debe estar correctamente asegurada al aro, utilizando un candado <br/>
3. Aparcar la bicicleta con sus accesorios no puede dificultar el estacionamiento de otros vehículos ni el paso.
En caso de incumplimiento de los artículos anteriores, la bicicleta en cuestión podrá ser retirada del aparcamiento, a expensas
del propietario de la bicicleta. Las bicicletas y bicicletas de carga no recogidas o reclamadas después de un período de 30 días a
partir de la fecha de retiro del estacionamiento se considerarán pérdidas. VIZI se reserva el derecho de no poder devolver la
bicicleta a su propietario después de este período. <br/>
`;


export const htmlDataforEnglish =`

Vizi Terms and Conditions <br /> 
General conditions <br />  
The Peruvian company Smart Mobility Solutions S.A.C, hereinafter VIZI, registered in Peru, with the RUC number:
20608273426, whose office is at Av. Rivera Navarrete 548, office 802, San Isidro, Peru, offers a service of
availability, visualization and separation of spaces for cyclists and those interested in allowing smaller vehicles to park.
Any user who uses the service to park their vehicles will be called "USER" from now on, and all
interested in making their space available so that they can park smaller vehicles will be called "THE PARTNER" from now on
onwards. <br /> 
USER and PARTNER declare to be aware of the need to have an updated mobile phone that contains capabilities
that allow the use of the VIZI application in order to be able to use all the functionalities of the service such and not
limited to: Geolocation, availability, separation and timing of times, spaces and places where THE
USER is interested in visiting and using. <br />
Any additional questions that need consultation, VIZI offers 1 service channel, through the mail: <a href = "ayuda@vizispot.com"> help@vizispot.com. </a>
So users can also visit <a href = "https://www.vizispot.com/">www.vizispot.com </a> <br /><br />
THE USER declares that he has read and accepted these terms and conditions prior to using the services of the application of
VIZI. <br /> <br />
<b> 1. Definitions </b> <br />
These General Conditions must be understood as: <br />
1.1. "User": any User who uses the VIZI services, in particular having access to at least one parking space.
parking made available by VIZI, regardless of the means by which you have received this or these accesses; <br />
1.2. “Partner”: any Partner that receives Users through VIZI services, in particular by offering access to at least
a parking space made available by VIZI, regardless of the means by which you received this or these
Access; <br />
1.3. "Services": all the services offered or provided by VIZI within the framework of its social activity, in particular the provision of
parking spaces to the User, through the Partners or means provided for this purpose by VIZI, in particular the
App; <br />
1.4. "Application": the "VIZI" mobile application that allows, in particular, access to available parking spaces; <br />
1.5. "Site": the website <a href = "https://www.vizispot.com/">www.vizispot.com</a> and its subdomains.<br /> <br />
<b> 2. Purpose  </b> <br />
The purpose of these General Conditions is to define the conditions and modalities under which VIZI places the
Services available to the User. <br /> <br />
<b> 3. Scope </b> <br />
3.1. These General Conditions are applicable to all the Services made available to the User and Member by
VIZI. These conditions apply to each visit or use of the Site or the Application and to each use of the car park. <br />
3.2. These General Conditions prevail over any other general conditions, particular conditions or
any other document you may have issued to VIZI. <br />
3.3. These General Conditions exclusively regulate the relations between VIZI and any User and Partner. <br />
3.4. The User declares to have read these Conditions and undertakes to comply with them. The Conditions are available and
can be consulted at any time at the following address: <a href = "https://www.vizispot.com/">www.vizispot.com</a> <br />
3.5. VIZI reserves the right to modify and update at any time, at its sole discretion and without prior notice, these
General conditions. The modified General Conditions are binding for the User each time the website is used.
Service. <br />
3.6. The nullity, expiration or inapplicability of one of the provisions of the General Conditions with the provisions
current legal provisions, will not affect the validity of the other provisions of the General Conditions that will retain their full
legal validity. <br /> <br />
<b> 4. Entry into force / Duration </b> <br />
4.1. These General Conditions will enter into force on the date they are put online and will be applicable on the date of
the first use of the services by the User and the partner. <br />
4.2. These General Conditions are enforceable throughout the duration of the use of the application and the Services, until
that the new General Conditions replace them. <br />
4.3. The User may at any time waive the use of the Application and the Services offered therein, but is
subject to the General Conditions until you request your official withdrawal from the service. <br /> <br />
<b> 5. Legal capacity of the User </b> <br />
5.1. The User expressly declares that he has the power, authority and capacity necessary for the celebration and execution
of the obligations incumbent on you for the use of the Site and the Services. <br />
5.2. Only Users who are at least 16 years of age may use the Service or the Application.<br />
5.3. The User declares that he is of legal age to enter into a binding contract. The Member who creates an account for
a legal person declares to be authorized to act in this way and to involve this person with respect to these
terms of use and to register you in order to use the Service and the Application. <br />
<br />
<b> 6. Accessibility of the Application and the Services  </b> <br />
The Application, the Website and the Services are normally accessible 24 hours a day, 7 days a week.
However, VIZI is not responsible for interruptions in the availability of the Application, the Website and the Services.
when these interruptions do not depend on your will. <br />
VIZI reserves the right, without prior notice or compensation, to temporarily close the Application, the Website or access to
one or more Services to update, modify or change operating methods, servers and accessibility hours, without
this list is exhaustive.
VIZI reserves the right, without prior notice or compensation, to permanently close the Application, the Website or one or more
Services, or to prevent certain Users from accessing the Website or one or more Services in application of these
General conditions.
VIZI reserves the right to make to the Application, the Website and the Services all the modifications and improvements that
considers necessary or useful for the proper functioning of its Site and the Services associated with it.<br /> <br />
<b> 7. Registration - Personal account - Conditions </b> <br />
7.1. The User who wishes to use the Services and/or the Application must create a personal account for this purpose through the Site.
Web and/or Application.. <br />
7.2. For this, the User must comply with the conditions established in article 5 of these Conditions. <br />
7.3. At the time of registration in the APP, the surnames, first names, email address, details of the
bicycle, the User's credit card and more information.
The User agrees to provide accurate, complete and updated information.
The User will also be asked to choose a strictly confidential username and password.
A confirmation email will then be sent to you. <br/>
7.4. The User is responsible for preserving the confidentiality of his username and/or password. The User will not have
right to distribute this login information, or to allow others to use it to access your VIZI profile. The User is
You agree to take reasonable steps to prevent others from obtaining such access information and to report any
unauthorized access directly to VIZI Customer Service via email. <br/>
7.5 VIZI reserves the right to verify the information provided at any time and to refuse service or use
of the application without having to justify your decision.<br/>
7.6. A VIZI user can register up to 2 vehicles in his profile. Subsequently, for each additional plate, the user will be
subject to an administrative fee of 10 soles. <br/>
7.7. A VIZI user can register up to 2 phone numbers in their profile. Subsequently, for each additional number, the
user will be subject to an administrative fee of 10 soles. <br/> <br/>
<b> 8. Use of the application or service  </b> <br/>
8.1. The User may only access the Services using the means of access provided for this purpose by VIZI. It corresponds to
User and Partner check that they download the application compatible with their phone. VIZI declines all responsibility if the
User's mobile phone is not compatible or if the User downloads an incompatible version of the application. VIZI reserves the
right to terminate the Services and the use of the application if the User uses the service or the application with a Mobile
incompatible or prohibited. <br />
8.2. By using the application or the service, the User or the Partner accepts:<br/>
1. use the Services or download the application only for your own personal use and not to resell them to a third party;<br/>
2. do not authorize third parties to use your account;<br/>
3. not assign or transfer your account to any other natural or legal person; <br/>
4.not use the service or the application for illegal purposes, in particular to send or save illegal content or for purposes
fraudulent; <br/>
5. not to use the service or the application in order to harm, embarrass or annoy third parties; <br/>
6. not compromise the proper functioning of the network; <br/>
7. not to interrupt the service or the application in any way; <br/>
8. not to copy or distribute the application or any other VIZI content without its written permission; <br/>
9. send us proof of identity upon request; <br/>
10. use an access point or 3G data account (AP) only if you are authorized to do so; <br/> 
11. not to use the service or the application with an incompatible or prohibited mobile phone; <br/>
12. to comply with all applicable laws or regulations of the country of origin, the country, the state and/or the city where you are
finds the User when using the application or service.<br/> 
8.3. The User undertakes to respect, and to guarantee respect, the general conditions, as well as the internal regulations (to
below), the safety regulations and signage, of the car park(s) made available to you. <br/>
8.4. The fraudulent use of the Site or that contravenes the general conditions may lead to the denial by VIZI,
at any time, from access to the Services offered by the Site, even if VIZI has confirmed access to the Services,
without prejudice to any accusation, offered to VIZI before the competent courts under these conditions
generals. On the other hand, VIZI reserves the right to immediately terminate the service and the use of the application if
the User does not comply with one of the rules established above. <br/> <br/>
<b> 9. Access to parking spaces </b> <br/>
9.1. The accessibility to the car park depends on the car park and the means of access chosen by the User or the Member: <br/>
A. Either by using the VIZI mobile app <br/>
B. Either by obtaining a badge, an access card, keys or other means of access provided by VIZI
(this means of access is subject to a deposit and stock availability)
In the case of using option A. above, the user must follow the exit procedure communicated by VIZI even if the
car park shutter/barrier is open, in order to notify VIZI of the end of the car park. Otherwise,
the use of the car park will continue to be billed to the user. <br/>
9.2. In the event that the user parks in a car park without a valid purchase, his vehicle may be collected as
indicated below in the section "Detention, immobilization, circulation and withdrawal of vehicles". <br/>
9.3. The car parks are only accessible to vehicles with a maximum length of 3.80 m. The height of the vehicles
may exceed that indicated at the entrance to the car park or imposed by the real situation. It is forbidden to enter the
parking with trailers of any kind, including caravans. Bicycles can also have access to the squares
parking spaces provided for this purpose. <br/>
9.4. If specific conditions apply to the car parks used, in addition to these general conditions, they will be indicated
on the proof of purchase provided at the end of the reservation process. <br/>
9.5. VIZI has the right to deny access to a user who does not comply with these general conditions, the conditions
of the car parks or the internal regulations of the car park made available to them. <br/> <br/>
<b> 10. Parking use  </b> <br/>
10.1. The user will only be able to access, park and leave the car park during the hours corresponding to their purchase. These will
they can view through their online customer account. The applicable opening hours may be modified by VIZI, which
will notify users through the Site, the Application or by email. <br/>
10.2. The User may only park their vehicle in spaces reserved for VIZI Users and corresponding to their type.
of vehicle. VIZI will notify you of these locations when you make your reservation. In no case will the user be able to park in a place
inconvenient or unauthorized. <br/>
10.3. In case of non-compliance with these general conditions, VIZI may withdraw the vehicle at the expense, risk and
risk of the User in accordance with the content below in the section "Detention, immobilization, circulation and withdrawal
of vehicles". VIZI will automatically and without formality charge the User's account for the costs of evacuating the vehicle without
prejudice to the exclusion of VIZI services as described in the section "Detention, immobilization, movement and
collection of vehicles".<br/>
10.4. The user and all accompanying persons are obliged to respect the peaceful nature of the neighborhood when
travel in and around the parking lot. The use of radios and other sources of noise in the car park is prohibited.<br/>
10.5. The user undertakes to leave the car park in a state of impeccable cleanliness. It remains strictly
It is forbidden to wash vehicles in the car park, carry out any work there or deposit or store anything that
is not a validly registered and insured vehicle or a bicycle identified and coupled, and used regularly. The
parking space is not a storage or long-term storage space. unused vehicles
must be evacuated with prior warning, as indicated below in the section "Detention, immobilization, circulation and
removal of vehicles".<br/>
10.6. Vehicles parked in the car park must meet the same conditions as those imposed on vehicles
parked on public roads. The User declares, in particular, that the vehicle(s) (except bicycles or scooters) that it parks
using the VIZI Services is or is the subject of a valid Civil Responsibility insurance and in order of payment. The code of
Circulation and any other regulations related to traffic on public roads apply in the car park, unless specified
otherwise. In all cases, the maximum authorized speed of vehicles is limited to 10 km/hour. <br/>
10.7. The bicycles must be validly attached to the posts provided for this purpose (maximum 2 bicycles per post, one at a time).
each side; a bicycle pass authorizes the parking of a single bicycle). The vehicles remain under full
responsibility of the User. Any object, whatsoever, left in the vehicle, or accessory attached to the vehicle, is
sole responsibility of the User. <br/>
10.8. The parking provision may not be subject to any transfer or sublease. <br/>
10.9. If the parking access time is exceeded by more than 30 minutes, a warning will be sent by mail
email to the user. In case of recidivism, an administrative fine of 20 soles will be billed to the user's account. <br/>
10.10. If a user is observed parking more vehicles in the car park than they have subscriptions, they will receive a notice
via email. <br/> <br/>
In case of recidivism, an administrative fine, equivalent to 1 month of subscription, will be billed to the user's account.
premium for additional vehicle. <br/> <br/> 
<b> 11. Detention, immobilization, circulation and removal of vehicles  </b> <br/>
11.1. In the event of an accident or involuntary immobilization of a vehicle, the user will immediately take the necessary measures
necessary to repair the vehicle in a manner that does not interfere in any way with normal traffic on the premises.
In any case, the User will pick up the vehicle before the end of the time slot during which it is authorized.
to access the car park. In all cases, the user will contact VIZI's customer service. <br/>
11.2. The user formally authorizes VIZI and/or the owner of the car park to move their vehicle or to move it, at the expense and
user's risk, in the event that: <br/>
❏ is not parked in the spaces provided; <br/>
❏ is left in the car park outside of accessibility hours without the prior written consent of VIZI; <br/>
❏ constitutes a danger to the people and/or property of others. <br/>
❏ interferes with normal circulation; <br/>
❏ not used (and a warning has been issued in advance); <br/>
❏ operational or security requirements require it. <br/>
In these cases, travel expenses are set at a flat rate of 300 soles plus taxes.<br/>
11.3. The user agrees to remove his vehicle from the site of the recovery company through which he has moved
within 24 hours after recovery. The User accepts that the recovery company will be authorized to
charge parking fees to the user after a period of 24 hours. <br/>
11.4. Bicycles and cargo bikes not picked up or claimed after a period of 30 days from the date of
removal from the parking lot will be considered forfeited. VIZI reserves the right not to be able to return the bicycle to its owner
after this period. <br/> <br/>
<b> 12. Prices  </b> <br/>
12.1. Registration via the app with VIZI is free. The price for each parking space is displayed on the site or
in the Application (hereinafter, the "Price") in soles with VAT included and varies according to the parking lot and the duration of the
parking lot. <br/>
12.2. In case of short-term use and without reservation, the rates are indicated per minute. The price charged depends on the time the
vehicle is in the car park calculated between the time of opening and the time of closing. In case of use with
reservation, prices are indicated per day or per month. If the User parks his vehicle beyond the duration of the reservation, the
rate per minute will be applied from the end of the reservation. <br/>
12.3. VIZI reserves the right to modify its prices at any time. However, the changes cannot have
retroactive effect. In the event of a price change by VIZI, the new price may take effect as soon as
as the next invoice is issued. <br/>
12.4. In case of non-compliance with the opening hours of the car park, VIZI reserves the right to apply a surcharge
per minute for each minute of overshoot. <br/>
12.5. Unless otherwise specified when making the reservation, any cancellation or modification of a reservation is
free up to 24 hours before the start of the reservation. Once the period of use has begun, it is due in full. <br/> <br/>
<b> 13. Rates </b> <br/>
VIZI charges variable rates based on prices to be able to make garages available to Users, explained in section 12; Y
fixed rates depending on the type of account that the User wants free or premium. The main premium feature is that
allows you to reserve a parking lot for short-term periods. The free version shows the parking lots but not the
reserved for the use of the User. <br/> <br/>
<b> 14. Payment </b> <br/>
14.1. There is only one means of payment to use the VIZI Payment Services: <br />
- Payment by credit and/or debit card <br/>
14.2. The provision of parking spaces to the User by VIZI is subject to payment, in accordance with point "12. Prices"
of these general conditions. <br/>
14.3. The user acknowledges that VIZI can debit the account of the card that he communicated when registering in the Services, for the
Services you requested (including taxes and possible late fees) without additional authorization. Payments made are not
refundable. <br/>
14.4. VIZI uses a third party payment system ("the payment system") to associate the User's card account with the
Application and Services. The processing of any payments or credits derived from the use of the application and the service by
part of the User will be governed by the conditions and the privacy policy of the payment system and by the conditions of the issuer
of the card, in addition to these conditions of use and in accordance with current legal provisions. <br/>
14.5. VIZI declines all responsibility in case of error due to the payment system. The User acknowledges and accepts that VIZI
You will obtain certain details of the transactions, which you will use in accordance with these general conditions and the current legal framework. <br/>
14.6. VIZI guarantees secure payments and respects SSL standards to protect data effectively. <br/>
14.7. In case of denial of payment authorization by bank card, VIZI reserves the right to act to recover
debts generated by payment reminders, temporary blocking of user access or transmission of the
record to an external collection company. <br/>
14.8. In case of non-payment on time, interest at the applicable legal interest rate in case of delay in commercial operations
in force at the time of non-payment, as well as an amount of 50 soles for recovery expenses, regardless
of the principal amount. <br/>
14.9. The user declares to have understood that any collection order issued must be paid in full and that the
Termination of the contract can in no case have a retroactive effect on the invoices issued. <br/> <br/>
<b> 15. Compensation </b> <br/>
By accepting these general conditions and by using the application or service, the User undertakes to defend, indemnify and
guarantee VIZI, its affiliates, its licensors and each of its officers, directors, other users, employees, agents. Y
agents against claims, costs, damages, losses, liabilities and expenses (including legal costs, fees and
attorneys' fees) arising out of or in connection with: <br/>
1. any violation of the provisions of these general conditions or of any applicable legislation or regulation, whether
whether or not mentioned in this document, of which the User is guilty; <br/>
2. any violation of the rights of third parties; <br/>
3. any fraudulent use of the application or service, of which the User is guilty. <br/> <br />
<b> 16. Liability </b>  <br/>
16.1. The information, recommendations and/or services provided to the User directly or indirectly by the Site, the Services and the
Application, constitute only general information and not opinions. VIZI will endeavor to maintain the Site, the Application and
its content in a correct and up-to-date state, but does not guarantee that the website and / or the application (and its content) are
free from errors, defects, malware or viruses, or that the Site and/or the Application are correct, current and accurate. VIZI will not be
responsible for any damage resulting from the use (or the inability to use) the Site or the Application, including damages caused
by malware, <br/>
16.2. VIZI shall not be liable for any damage resulting from the use (or inability to use) of electronic means of
communication with the Site or the Application, in particular damages resulting from a failure or delay in the transmission of the
electronic communications, the interception or manipulation of electronic communications by third parties or by
computer programs used for electronic communications and the transmission of viruses. <br/>
16.3. VIZI and the owner(s) of the car parks made available to Users decline all responsibility, for
any reason and for any reason, for any damage generally of any kind, tangible or intangible, to persons or
goods such as, in particular: accidents, theft or damage, even partial, that may occur on the occasion of or due to
parking use. The user undertakes to immediately report any damage to customer service
of VIZI. <br/>
16.4. In addition, by not having the capacity of depository, VIZI does not assume in any way an obligation of custody or surveillance
and incurs no liability for the actions of third parties. <br/>
16.5. VIZI only contracts obligations of means, for all Services offered or provided. VIZI is not done
responsible for any inconvenience or damage inherent to the use of the Internet network, in particular an interruption in service,
an external intrusion or the presence of computer viruses, or any event that can be classified as force majeure. In
In any case, the responsibilities of VIZI under the terms of these general conditions may not exceed one
amount equal to the amounts paid or payable during the operation that gives rise to said liability, whatever
be the cause or form of the action in question. <br/>
16.6. The User is solely responsible for any damage to their computer / cell phone or any loss of data resulting from the
download, consultation and use of the Website and/or the Application. <br/>
16.7. The User acknowledges that he has the option, without any obligation and under his sole responsibility, to use the networks
social, like Facebook, Twitter, Google, etc. VIZI is not responsible in any way for the use or treatment of this
information, data and content within the framework of social networks. <br/>
16.8. The User must make sure, before any use of this option, of knowing the conditions of use of these networks
social, to implement the protection measures and parameters necessary for proper use, to respect the
conditions of use of these social networks. <br/>
16.9. The User agrees to use the Website, the Application and the Services only for private purposes and with a
purpose in accordance with public order, good morals and the rights of third parties. <br/>
16.10. The User agrees not to take any action that may, directly or indirectly, undermine the integrity of the
Website, Application and/or Services made available to you by VIZI. <br/>
16.11 In the event of loss, theft or serious degradation of the parking access badge, the user will be charged a fixed fee
of 500 soles with taxes included. <br/> <br/>
<b> 17. License by the user </b> <br />
17.1. The User expressly acknowledges, exclusively and free of charge, to VIZI, the right to exploit any content
published by the User through the Application and/or the Website on the Internet, on social networks or by any known means
or unknown to date, for commercial or advertising purposes, all without territorial restrictions and while VIZI operates
the Application and/or the Website subject to these general conditions. <br />
17.2. However, VIZI has no obligation to use and/or exploit the content(s) posted by the User to
through the Application and/or the Website. <br />
17.3. The User acknowledges that VIZI acts as a simple intermediary for the distribution of the user's content and is not
responsible to anyone for the content or accuracy of user content. VIZI will not permanently control the
posted user content and will not play a moderating role between users. In addition, VIZI will not be obliged to
do it. Without limiting the foregoing, the User acknowledges and agrees that the comments, opinions, suggestions and other information
expressed or included in user content do not necessarily reflect the views of VIZI. <br />
17.4. The User declares and guarantees that the content of the user that he publishes or transmits comes from him, is not plagiarism of
creations of third parties, does not constitute a violation of the intellectual property rights of third parties, the right to life,
privacy or personality rights, and that they do not contain defamatory or derogatory comments. <br />
17.5. The User accepts and undertakes to permanently guarantee VIZI, its subsidiaries and its licensors against all
the costs, expenses, damages, losses and commitments incurred or suffered by VIZI or its related companies due to the
user content posted or transmitted by you, or resulting from your use of the Website, the Services or the Application. <br />
17.6. VIZI reserves the right to block or remove, in whole or in part, user content posted or transmitted, if
VIZI considers that you do not comply with these Terms of Use or that it is unacceptable for any other reason. <br />
17.7. The User agrees to notify VIZI in writing as soon as possible if any User Content violates these Terms.
of use. The User undertakes to provide VIZI with sufficient information so that it can verify whether this content of
User violates these Terms of Use. However, VIZI does not warrant or represent that it will block or remove (in whole or in part)
part) the user content in question. <br /> <br />
<b> 18. Grant of license for application </b> <br />
18.1. If the User complies with these Terms of Use, VIZI grants him a limited, non-exclusive and non-transferable license that
allows you to download and install a copy of the Application on a mobile phone that you own or control, and use this copy of the
Application for personal use only. <br />
18.2. The User may not: <br />
1. license or sublicense, sell, resell, transfer, assign, distribute or commercially exploit or make available to
third parties the service or application in any way; <br />
2. modify the service or application or create derivatives thereof; <br />
3. create electronic links to the Services or frame or mirror any application on any other server or
wireless or internet-based device; <br />
4. reverse engineer or access the application to a. design or build a competitive product or service; <br />
b. design or build a product using similar ideas, features, functions or images of the service or application;
vs. copy ideas, features, functions or images of the service or application; <br />
5. Launch an automated program or script, including software robots, crawlers, web robots, web browsers,
web indexers, zombies, viruses or worms, or any program capable of sending multiple requests per second to a server
or to inadvertently complicate or hinder the operation and/or performance of the service or application. <br />
18.3. The User may not: <br />
1. send or save unsolicited or repetitive messages, in violation of applicable law; <br />
2. submit or save infringing, obscene, threatening, defamatory, or illegal or unlawful content, including harmful content submitted
to children or violates the privacy rights of others; <br />
3. submit or save content that contains viruses, worms, Trojan horses or other computer code, files, scripts, agents or
harmful programs; <br />
4. hinder or interrupt the integrity or proper functioning of the website, application or service or the data that
they contain; <br />
5. attempt to fraudulently access the website, application or service or the systems or networks that support them. <br />
18.4. VIZI shall have the right to investigate the above violations, to the fullest extent permitted by law, and to initiate
Legal procedures. VIZI may appeal to and cooperate with law enforcement authorities to prosecute users who
violate these Terms of Use. <br />
18.5. The User acknowledges that VIZI is not obliged to control their access to the website, services, application or collective content
nor to the use made of it, nor to verify or edit collective content. The User acknowledges, however, that VIZI has
right to control your access within the framework of the operation of the website, the application and the service, to guarantee the
compliance with these Terms of Use or to comply with the law. applicable or a court order or request for a
court, administrative body or other official body. VIZI reserves the right to cancel or deactivate, at any
time and without prior notice, access to any collective content if you consider that it violates these conditions. <br /><br />
<b> 19. Intellectual property rights  </b> <br />
19.1. The Application and the Website contain texts, images (still or animated) and sounds, which are the exclusive property of VIZI.
These elements are protected by copyright, as well as by intellectual property and for the whole world (in
hereinafter, "Intellectual Property Elements"). <br />
19.2. The VIZI name, logo, and product names associated with the App and Service are trademarks
of VIZI, its related companies or third parties. VIZI does not grant any right or license to use these marks. <br />
19.3. The Intellectual Property Elements are made available to the User, free of charge, for the exclusive use of the
services and within the framework of the normal use of its functionalities. This authorization to use the Property Elements
Intellectual is done on a personal, non-exclusive and non-transferable basis. VIZI may revoke this authorization to use the
Intellectual Property Elements at any time. <br />
19.4. Any copy or falsification of the concept or format of the Website and / or the Application by any means and in any
medium is strictly prohibited. <br />
19.5. Any use in any form must be subject to the prior written authorization of VIZI. <br />
19.6. In case of non-compliant or abusive use of the Intellectual Property Elements, VIZI reserves the right to terminate
the infringement of your intellectual property rights by all legal means. <br />
19.7. Any violation may lead to civil and/or criminal proceedings. <br /><br />
<b> 20. Interactions with third parties </b> <br />
20.1. When using the Website, Application and Services, links to websites owned and controlled may be provided.
by third parties in order to put the User in contact with these third parties, to purchase their goods. or its services, to
participate in their promotions. These links take the User out of the website, the application and the services and are not controlled
by VIZI. VIZI is not responsible and cannot be held liable for the content and activities of these websites. The User agrees,
visit and use these websites at your own risk. <br />
20.2. These other websites may send their own cookies to users, collect data or request personal information.
VIZI advises that you check the terms of use and privacy policy of these websites before using them. <br /> <br />
<b> 21. Duration and termination of the contract </b> <br />
21.1. After the registration of the User or the Partner in VIZI and the acceptance of these General Conditions, a contract is formed
indefinite between VIZI and the User through which the latter can use VIZI's services. <br />
21.2. The User can stop using the VIZI services at any time by permanently deleting the application
installed on your smartphone and closing your user account by following the instructions on the VIZI website. All the
Consumptions not yet billed are still payable. The user will be able to cancel their reservation free of charge no later than 24
hours before the start of the reservation period for the location. <br />
21.3. The subscriber user agrees for a minimum period of 3 months from the first day of the month following the
start of your subscription. The nature of the contract that binds you to VIZI is a monthly contract with tacit renewal (at
unless otherwise specified). After 3 months, the subscriber user will stop using the services by closing the
subscription in your account. Cancellations are effective the last day of the month. <br />
21.4. VIZI may stop providing the Services at any time, with immediate effect (disabling the possibilities of
use of the application and the Services) and without refund. <br />
1. if the User violates these general conditions; <br />
2. if VIZI considers that the User is abusing the application or the Services. VIZI is not obliged to warn the User
in advance of the termination of the Services you use. <br />
21.5. VIZI reserves the right to terminate all or part of the right of access to the personal accounts of the
Registered users or even to delete their account, due to lack of use and activation of the account for a period of one year
and/or in the event that the registered User has violated these General Conditions. <br />
21.6. VIZI may proceed with any restriction and/or removal without prior notice and may also not be considered
responsible for the loss of data resulting from the deletion of the User's personal account. <br />
21.7. VIZI may stop providing the Services at any time, with immediate effect (disabling the possibilities of
use of the application and the Services) at its discretion and even if the current period has already been billed. To the extent that the
user is not responsible for the interruption of services, the amounts of overpayment may be refunded and
accredited as decided by VIZI. <br /> <br/>
<b> 22. Protection of privacy with regard to the processing of personal data </b> <br/>
22.1. The User guarantees to provide correct and complete personal data when placing an order or registering in the VIZI App. <br/>
22.2. VIZI undertakes not to disclose personal data to third parties. These are confidential. They will only be used by their
internal departments for the processing of reservations, for communication and personalization purposes. <br/>
22.3. In case of transfer or use by third parties of personal data, VIZI undertakes to inform the User in advance
in order to allow you to exercise your right to object. VIZI can also provide consolidated statistics
related to its users, reservations, the structure of exchanges and information on the Site to trusted third parties,
but these statistics will not contain any personal data. However, this item cannot prevent the sale or
transfer of activities to a third party. <br/>
22.4. In accordance with the General Data Protection Regulation (GDPR), the User has the right to access, control,
rectification and possibly complete deletion of your personal data. Any request in this regard may
be managed through the user's customer account, or through VIZI's Customer Service by mail
email with proof of identity (photo of identity document). In accordance with the law, VIZI reserves the right to
keep the user data necessary for its operations and for the control of the same by institutions
authorized for a period of 2 (two) years. <br/>
22.5. The request for deletion of personal data cannot be applied while a contract is in progress or to reach a
booking. This request will not be automatically associated with a request to close the contract, these must be closed
previously in accordance with the conditions described in point 21. <br/>
22.6. The Site uses the use of cookies. The cookie is a computer file, stored on the hard drive of the personal computer
of the user, whose purpose is to inform of a previous visit of the User or the user to the Site. Cookies are, among other things,
used by VIZI in order to personalize the service offered to users. The User retains the possibility of rejecting the
cookies through the configuration of your Internet browser. Then you lose the possibility of personalizing the service that
provides VIZI. <br/>
22.7. Some web pages on the site may sometimes contain electronic images, which are used to count the number of
page visitors. This information may be used by some of VIZI's partners, in particular to measure and improve
the effectiveness of certain actions. The information obtained simply allows statistics to be compiled on the number of
visits to certain pages of the Site. <br/> <br/>
<b> 23. Do not quit </b> <br/>
23.1. The fact that VIZI does not take advantage of a breach of the User or the Partner in any of its obligations
contractual or legal may not be construed as a waiver of taking advantage of this breach or this
breach. <br/>
23.2. The fact that VIZI does not make use of a clause of these Conditions does not imply a waiver of the benefit of said
clause. <br/> <br/>  
<b> 24. Applicable Law and Dispute Resolution </b> <br/> 
24.1. Only Peruvian law is applicable to contractual relationships. In case of litigation, only the courts will be competent.
Peruvians from the judicial district of Lima. For any dispute over the jurisdiction of the Justice of the Peace in the judicial district of Lima. <br/> <br/>
<b> 25. Internal regulations of VIZI car parks </b> <br/>  
25.1. Only VIZI members are authorized to remain in the parking spaces, in accordance with the
conditions defined below. <br/>
25.2. The car park is accessible according to the hours indicated in the VIZI application. VIZI reserves the right to withdraw the
parking availability on certain dates. <br/>
25.3. The vehicle must be parked in an authorized space designated for your vehicle type. Under no circumstances the
user may park in an inconvenient or unauthorized place. <br/>
25.4. The maximum speed of the vehicles is limited to 10 km / hour and the regulations of the highway code are applicable in
the parking. <br/>
25.5. The user is obliged to respect the peaceful nature of the neighborhood and the good state of maintenance of the facilities.
The use of radios and other sources of noise in the car park is prohibited. Users are strictly prohibited from washing
your car in the parking lot and carry out any work there. <br/>
25.6. While the vehicles are parked in the car park, no person or animal may remain there. <br/>
25.7. Vehicles are the sole responsibility of the user. Any object, whatsoever, left in the vehicle, is
sole responsibility of the user. This also applies to all vehicle accessories. <br/>
25.8. The owner of the car park or Member is limited to making parking spaces available to VIZI users and, therefore,
Therefore, it does not assume any responsibility of any kind. In addition, VIZI and the owner decline all responsibility, for
any reason and for any damage generally of any kind, tangible or intangible, in particular: accidents, theft or
damage, even partial, that may occur from time to time or due to the use of the car park, including in particular during the
arrival or departure of a user or their stay in the car park. <br/>
25.9. VIZI and the owner are authorized to remove from the parking lot the vehicles of users without title or that do not respect the
time slots defined in articles 2 and 3 or that are parked in inconvenient places. VIZI will charge
automatically and without prior notice in the user's account the cost of evacuating the vehicle. <br/>
25.10. For any technical problem with the barriers or in case of emergency, the user will contact the
VIZI assistance service at the VIZI signs inside the car park. <br/>
25.11. Any violation of said internal regulations or of the general conditions of VIZI by the user will give rise to
the total exclusion and without prior notice of the member from the VIZI service. The user will not be able to claim reimbursement for their consumption or
current subscriptions. <br/>
25.13. Specific rules for bicycle parking <br/>
25.13. 1. Only users with a VIZI bike subscription in good standing can park their bike on the posts provided
For this end. <br/>
25.13. 2. A subscription for a bicycle space entitles the subscriber to park a single bicycle (bicycle in
standard format), a subscription for a space for cargo bikes only one cargo bike. <br/>
25.13. 3. The bicycle for which the subscription is made in a VIZI car park must have a unique number that can be
get in the VIZI app. This number must be affixed to the bicycle frame in the prescribed place (using the
sticker provided for this purpose). <br/>
25.13.4. Bicycle parking on the poles is as follows: <br/>
1. An arch allows the parking of 2 bicycles, one on each side <br/>
2. The bicycle must be properly secured to the rim, using a padlock <br/>
3. Parking the bicycle with its accessories cannot hinder the parking of other vehicles or the passage.
In case of non-compliance with the previous articles, the bicycle in question may be removed from the parking lot, at the expense
of the bicycle owner. Bicycles and cargo bikes not picked up or claimed after a period of 30 days to
partir de la fecha de retiro del estacionamiento se considerarán pérdidas. VIZI se reserva el derecho de no poder devolver la
bicicleta a su propietario después de este período. <br/>
`;



