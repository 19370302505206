import * as React from "react";
import PropTypes from "prop-types";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import "../reusableComponent/global.css";
import { useState, useEffect } from "react";
import Http from "../global/http/HttpRequest";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  padding: 10px 10px 10px 13px;
  text-align: left;
  height:"48%";
  marginTop:"10%";
  line-height: 2;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }


  }



  &::after {
    content: '▾';
    float: right;

  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px 0px 0px;
  height:"10px";
  background:"white";
  width: 100%;
  
  z-index: 10;
  background: '#fff';
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 16px;
  border-radius: 0.45em;
  cursor: default;
  background:"white";
  width:"100000000000000000000px"
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 10;
  background-color: "blue";
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return (
    <SelectUnstyled
      // name="IdTypeId"

      onChange={props.onChange}
      {...props}
      components={components}
    />
  );
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};

export default function DropDownComponent(props) {
  const [data, setData] = useState([]);
  let navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    Http.get(`id-type`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let statusCode = err.response.data.statusCode;
        let response = err.response.data.message;
        navigate({
          pathname: `/content/errorpage`,
          search: `statusCode=${statusCode}&response=${response}`,
        });
      });
  }, []);

  return (
    <>
      <div
        className=""
        style={{
          height: "80px",
        }}
      >
        <CustomSelect
          style={{
            display: "block",
            width: "70%",
            border: `${
              props.error === `${t("idtypeerror")}`
                ? " 1px solid #FF0000"
                : "1px solid grey"
            }`,
            marginTop: "6%",
          }}
          renderValue={props.renderValue}
          onChange={props.onChange}
          onListboxOpenChange={props.onBlur}
          name={props.name}
          error={true}
        >
          {data.map((option, index) => (
            <div
              style={{
                background: "white",
                textAlign: "left",
                opacity: 1,
              }}
            >
              <StyledOption
                style={{
                  zindex: "10px",
                  opacity: 10,
                }}
                key={option.typeDisplay}
                value={option.id}
              >
                {option.typeDisplay}
              </StyledOption>
            </div>
          ))}
        </CustomSelect>

        {props.error !== "" ? (
          <div
            style={{
              width: "64%",
            }}
          >
            <p
              style={{
                textAlign: "left",
                fontSize: "64%",

                marginTop: "-2%",
                //  padding:"0.4% 0% 0% 0%",
                lineHeight: "300%",
                fontFamily: "CircularStd",
                color: "#FF0000",
                fontWeight: 250,
              }}
            >
              {props.errormessage}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
