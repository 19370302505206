import React, { useEffect, useState } from "react";
import "./passwordstyle.css";
import { Typography } from "@material-ui/core";
import { passwordRegex } from "./PasswodRegex";
import { useTranslation } from "react-i18next";

const PasswordStrengthMeter = ({ password }) => {
  const [passwordStrength, setPasswordStrength] = useState("");
  const [progressBar, setProgressBar] = useState("");
  const  {t} = useTranslation();

  useEffect(() => {
    calculateStrength(password);
  }, [password]);

  const calculateStrength = (password) => {
    if (passwordRegex.REGEX_STRONG.test(password)) {
      setPasswordStrength(t('high'));
      setProgressBar("100");
    } else if (
      passwordRegex.REGEX_MEDIUM1.test(password) ||
      passwordRegex.REGEX_MEDIUM2.test(password)
    ) {
      setPasswordStrength(t('medium'));
      setProgressBar("50");
    } else {
      setPasswordStrength(t('low'));
      setProgressBar("30");
    }
  };

  //  calculateStrength(password);
  //   console.log(passwordStrength)

  const funcProgressColor = () => {
    switch (passwordStrength) {
      case `${t("low")}`:
        return "#FF1654";
      case `${t("medium")}`:
        return "#FF7F11";
      case `${t("high")}`:
        return "#10B76A";
      default:
        return "#FF1654";
    }
  };

  const changePasswordColor = () => ({
    width: `${progressBar}%`,
    background: funcProgressColor(),
    height: "5px",
    opacity: 1,
    padding: "1px 1px 1px 1px",
    borderRadius: "10px",
  });

  return (
    <>
      <div className="progress" style={{ height: "5px" }}>
        <div className="progress-container">

       
        <div className="progressbar" style={changePasswordColor()}></div>
        
      </div>
      <Typography
          className="text"
          style={{
            color: funcProgressColor(),
            fontWeight: "bold",
            fontSize: "80%",
            fontFamily: "CircularStd",
          }}
        >
          {passwordStrength}
        
        </Typography>
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
