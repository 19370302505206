 const buttonOnActive = {
  buttonOnActive: {
    width: "70%",
    background: "black",
    height: "70%",
    color: "white",
    fontWeight: "bold",
    borderRadius: "1.5rem",
    background: "#10B76A",
    color: "#FFFFFF",
    fontFamily: "CircularStd",
    padding: "3% 4% 3% 3%",
    margin: "3% 1% 7% 5%",
  },
};

const buttonNonActive = {
  buttonNonActive: {
    width: "70%",
    background: "black",
    height: "70%",
    color: "white",
    fontWeight: "bold",
    borderRadius: "1.5rem",
    backgroundColor: "#D9E7EB",
    color: "#7C8E93",
    fontFamily: "CircularStd",
    padding: "3% 4% 3% 3%",
    margin: "3% 1% 7% 5%",
  },
};


export {buttonNonActive,buttonOnActive }