import React from 'react';
import { Typography } from '@material-ui/core';
import { SecuritySharp } from '@material-ui/icons';
import {InfoStyle} from "../global/globalStyle/TypographyStyle"
import { useTranslation } from 'react-i18next';


function PasswordInfoComponent() {
  
  const {t} = useTranslation();


return (<>
  
  <Typography variant="subtitle1" style={InfoStyle}>
                  <SecuritySharp
                    style={{
                      fontSize: "1rem",
                      color: "grey",
                      margin: "-1% 1% -0.2% 1%",
                    }}
                  />
                 {t("passwordInfo")}
                </Typography>
  
  
  </>);
}

export default PasswordInfoComponent;
